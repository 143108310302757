
export const parseData = (inputData) => {
    const outputData = [];

    for (let i = 0; i < inputData[0].values.length; i++) {
        const record = {};
        for (const entry of inputData) {
            record[entry.key] = entry.values[i];
        }
        outputData.push(record);
    }

    return outputData
}

export const dataStructuring = (sorted_data, label_key, metric_key, series_key) => {
    let series_list = [...new Set(sorted_data.map((ele) => ele[series_key]))]
    let label_list = [...new Set(sorted_data.map((ele) => ele[label_key]))]

    let temp = {}

    for (let item of sorted_data) {
        if (!(item[series_key] in temp)) temp[item[series_key]] = {};
        temp[item[series_key]][item[label_key]] = item[metric_key]
    }


    let data_mapping = {}

    for (let series of series_list){
        let list = []
        for (let label of label_list) {
            list.push(temp?.[series]?.[label] ? temp[series][label] : null)
        }
        data_mapping[series] = list
    }

    return { x_fields: label_list, series_fields : series_list, data_mapping: data_mapping  }
}

