import React, { useContext, useState } from 'react'
import { exportingTab } from '../../utils/exportingTab';
import { Popover } from 'antd';
import { DataContext } from '../../context/ContextProvider';
import styles from "./Heading.module.css"
import { Button } from 'antd/es';
import { useLocation } from 'react-router-dom';


function convertArrayOfObjectsToCSV(args) {

    args = [...args.map((ele)=> ({"City Name": ele.city, "Request Count": ele.count}))]
    const result = [];
    const keys = Object.keys(args[0]);

    result.push(keys.join(','));

    args.forEach((item) => {
        const values = keys.map((key) => (item[key] || '').toString());
        result.push(values.join(','));
    });

    return result.join('\n');
}


function downloadCSVFile(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}



export const Heading = ({ label, fields, data }) => {

    const { selectedFilters, downloadTabSelectedFilters, cityFilterDefaultValue, selectedVehicleCategory, getAccessTokenSilently, createDimensionsFilters } = useContext(DataContext)
    const [open, setOpen] = useState(false);
    const currentTab = useLocation().pathname


    const downloadData = async () => {
        setOpen(false)
        try {
            const accessToken = await getAccessTokenSilently();

            let headers = { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" }

            let dimensionsFilters = createDimensionsFilters({ currentTab: currentTab })

            if (currentTab.includes("/heat-map")) {
                dimensionsFilters.push({ "fieldName": "source_vehicle_category", "values": [selectedVehicleCategory.left.options[0].value, selectedVehicleCategory.right.options[0].value], "negation": false })
            }

            if (currentTab.includes("/download")) {
                if (dimensionsFilters.find((ele) => ele.fieldName === "country_code_currency")) {
                    dimensionsFilters = dimensionsFilters.filter((ele) => ele.fieldName !== "country_code_currency")
                    dimensionsFilters.push({ fieldName: "city", values: cityFilterDefaultValue.options.map((ele) => ele.value), negation: cityFilterDefaultValue.negation })
                }
            }

            let payload = JSON.stringify({
                dateRange: currentTab.includes("/download") ? downloadTabSelectedFilters.date_range : selectedFilters.date_range,
                dimensionsFilters: dimensionsFilters,
                fields: fields,
                isPilot: currentTab.includes("/pilot-main-tab") ? true : false
            })

            const documentContent = exportingTab({ label, headers, payload })

            const newTab = window.open('', '_blank');
            newTab.document.write(documentContent);

            newTab.document.close();
        } catch (error) {
            console.log(error)
        }
    };

    const handleDownloadCSV = () => {
        const csvData = convertArrayOfObjectsToCSV(data);
        downloadCSVFile(csvData, 'usesStatsVsCity.csv');
        setOpen(false)
    };

    return (
        <div className={ styles.heading }>
            <div className={ styles.text }> { label }</div>
            { fields && <Popover content={
                <span className={ styles.pop_up }>
                    <Button onClick={ downloadData }>Export</Button>
                </span>
            } placement="bottomRight" trigger="click" open={ open } onOpenChange={ (newOpen) => setOpen(newOpen) } >&#8942;</Popover> }
            { data && <Popover content={
                <span className={ styles.pop_up }>
                    <Button onClick={ handleDownloadCSV }>Download</Button>
                </span>
            } placement="bottomRight" trigger="click" open={ open } onOpenChange={ (newOpen) => setOpen(newOpen) } >&#8942;</Popover> }
        </div>
    )
}
