
export const numberNotation = (value) => {
  const suffixes = ["", "K", "M", "B", "T"];

  const suffixIndex = (Math.floor((value.toString().length - 1) / 3));

  const shortValue = (value / Math.pow(1000, suffixIndex)).toFixed(1);

  return `${shortValue}${suffixes[suffixIndex]}`.replace(".0", "");
}
