import { apiService } from './apiService';

export const RequestAccess = async (getAccessTokenSilently) => {

    const accessToken = await getAccessTokenSilently()

    const response = await apiService.get("/requestAccess",
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        }
    )

    if (response.status === 200) {
        return true
    }

}
