import { apiService } from './apiService';

export const FetchStatsData = async ({ dateRange, countryList, cityList, getAccessTokenSilently, cancelTokenSource }) => {

    const accessToken = await getAccessTokenSilently()

    const response = await apiService.post("/getUserMetrics",
        {
            dateRange: dateRange,
            countries : countryList,
            cities: cityList
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            cancelToken: cancelTokenSource.token
        }
    );
    return await response.data

}