export const uniqueList = (data) => {
    if (data.length === 0) return data

    const uniqueMap = new Map();

    data.forEach(item => {
        const { key, isCompletedField } = item;
        const keyString = JSON.stringify(key.value);

        if (!(keyString in uniqueMap)) uniqueMap[keyString] = new Array(2)

        if (isCompletedField === false) { uniqueMap[keyString][0] = item } else { uniqueMap[keyString][1] = item }
    });

    let uniqueData = []

    for (let key in uniqueMap) {
        if (uniqueMap[key][1] === undefined) {
            uniqueData.push(uniqueMap[key][0])
        } else {
            if (uniqueMap[key][1].options.length === uniqueMap[key][0].options.length) {
                uniqueData.push(uniqueMap[key][0])
            } else {
                uniqueData.push({
                    key: uniqueMap[key][1].key,
                    options: uniqueMap[key][1].options,
                    isCompletedField: uniqueMap[key][1].isCompletedField,
                    default: {options: uniqueMap[key][0].options, negation: false}
                })
            }
        }
    }

    return uniqueData;
}

// export const uniqueList = (data) => {
//     if (data.length === 0 ) return data

//     const uniqueMap = data.reduce((map, item) => {
//         const key = item.key.value;
//         const options = item.options;
//         if (!map.has(key) || map.get(key).options.length < options.length) {
//             map.set(key, item);
//         }
//         return map;
//     }, new Map());
//     const uniqueData = Array.from(uniqueMap.values());
//     return uniqueData;
// }

