import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from "jwt-decode";
import styles from "./Styles.module.css"
import { Button } from 'antd';
import { RequestAccess } from '../services/RequestAccess';

export const Authorization = ({ children }) => {

    const { user, getAccessTokenSilently, logout } = useAuth0();
    const [isAuthorization, setIsAuthorization] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        const getUserToken = async () => {
            let token = await getAccessTokenSilently()
            const decoded = jwtDecode(token);

            setIsAuthorization(decoded?.permissions?.includes("read:data"))
        }

        getUserToken()
    })

    const requestAccess = async () => {
        try {
            const response = await RequestAccess(getAccessTokenSilently)

            if (response) {
                setIsDisabled(true)
            }
        } catch (error) {
            console.error("Error in Request: ", error)
        }
    }



    return isAuthorization ?
        <>{ children }</> : isAuthorization === false ?
            <>
                <Button className={ styles.ant_btn } onClick={ () => logout({ logoutParams: { returnTo: window.location.origin } }) }>
                    Log Out
                </Button>
                <div className={ styles.auth_tab }>
                    <div className={ styles.gif }>
                        <img src='character.png' alt="character" />
                        <img src='eye.gif' alt="eye" />
                        <img src='stop.png' alt="stop" />
                    </div>
                    <div className={ styles.error_display }>
                        <span>Hey! { user.name },</span>
                        <strong>{ user.email }</strong>
                        <h1>
                            <span>is not</span>
                            <br />
                            Authorized
                        </h1>
                        <div>
                            <p>Let Us Notify About You...</p>
                            <button onClick={ requestAccess } disabled={ isDisabled } style={ { backgroundColor: isDisabled ? "rgba(71, 234, 74, 0.3)" : "" } } className={ styles.button }>{ isDisabled ? "Request Sent" : "Request access" }</button>
                        </div>
                    </div>
                </div>
            </> : null
}
