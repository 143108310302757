import React, { useContext, useEffect, useRef } from 'react'
import styles from './Navbar.module.css'
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Popover } from 'antd';
import { DataContext } from '../../context/ContextProvider';
import { hashing } from '../../utils/hashing';
import { defaultDate } from '../../utils/defaultDate';
import { useLocation } from 'react-router-dom';

export const Navbar = ({ project_name, routes }) => {

    const { resetFilters, defaultCity, defaultCityPt, selectedFilters, selectedFiltersPt, downloadTabSelectedFilters, userStatsSelectedFilters } = useContext(DataContext)
    const { user, isAuthenticated, logout } = useAuth0();
    const resetId = useRef(null)
    const resetIdPt = useRef(null)
    const resetIdUS = useRef(null)

    useEffect(() => {
        const getResetId = async () => {
            let hash = await hashing({ city: { options: defaultCity.current, negation: false } }, defaultDate())
            resetId.current = hash

            hash = await hashing({ city: { options: defaultCityPt.current, negation: false } }, defaultDate())
            resetIdPt.current = hash

            hash = await hashing({}, defaultDate())
            resetIdUS.current = hash
        }
        getResetId()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const currentTab = useLocation().pathname;

    useEffect(() => {
        const currentPage = routes.find(route => route.endpoint === currentTab)

        if (currentPage) {
            document.title = `${project_name} : ${currentPage.name}`
        } else {
            document.title = `${project_name}`
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    return (
        <header className={ styles.header }>
            <div>
                <img src='logo.png' alt="LOGO" />
                <h2>{ project_name }</h2>
            </div>
            <div >

                <Button
                    className={ styles.reset_btn }
                    onClick={ (e) => resetFilters(e.target.baseURI) }
                    disabled={
                        currentTab === "/download-tab" && downloadTabSelectedFilters.id === resetId.current ? true :
                            currentTab === "/user-stats-tab" && userStatsSelectedFilters.id === resetIdUS.current ? true :
                                currentTab === "/pilot-main-tab" && selectedFiltersPt.id === resetIdPt.current ? true :
                                    selectedFilters.id === resetId.current ? true : false }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="currentColor" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"></path>
                    </svg>
                    <span>Reset</span>
                </Button>

                <div className={ styles.account }>
                    <Popover content={
                        <span className={ styles.pop_up }>
                            { isAuthenticated && <>
                                <h2>Name:- { user.name }</h2>
                                <h3>Email:- { user.email }</h3>
                                <button onClick={ () => logout({ logoutParams: { returnTo: window.location.origin } }) }>
                                    Log Out
                                </button>
                            </> }
                        </span>
                    } placement="bottomRight" trigger="hover" >{ isAuthenticated ? <img src={ user.picture } alt={ user.name } /> :
                        <img src="user.png" alt="user" /> }</Popover>
                </div>
            </div>
        </header>
    )
}
