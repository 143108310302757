import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/ContextProvider';
import styles from './Sidebar.module.css';

export const Sidebar = ({routes}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { sideBarRef, isExtended, setIsExtended, adminAccess } = useContext(DataContext);

    return (
        <div ref={ sideBarRef } className={ styles.sidebar } style={ { minWidth: isExtended ? "257px" : "57px", left: "0px", overflow: "auto" } }>
            <div className={ styles.pagination }>
                { routes.filter((route)=> adminAccess ? route : route.onlyAdminAllow === false).map((route, index) => {
                    return (
                        <li key={index} style={ { textAlign: isExtended ? "left" : "center" } } onClick={ () => navigate(route.endpoint) }>
                            { isExtended === false ?
                                <span className={ `${styles.page_no} ${location.pathname === route.endpoint && styles.active}` } >{ index + 1 }</span> :
                                <span className={ `${styles.page_name} ${location.pathname === route.endpoint && styles.active}` } >{ route.name }</span>
                            }
                            <span className={ styles.page_name_float }>{ route.name }</span>
                        </li>
                    )
                }) }
            </div>
            <div className={ styles.extender }>
                <img onClick={ () => setIsExtended(!isExtended) } src={ isExtended ? 'left_arrow.png' : 'right_arrow.png' } alt='>' />
            </div>
        </div>
    );
};
