import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/Page.css'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { Loading } from '../components/Loader/Loading'
import { LineCharts } from '../components/visualize/LineCharts'
import { DataTable } from '../components/visualize/DataTable'
import { FetchRenderData } from '../services/FetchRenderData'
import { parseData } from '../utils/parseData'
import { customSort } from '../utils/customSort'
import { ScatterCharts } from '../components/visualize/ScatterCharts'
import axios from 'axios'
import { AvailabilityChart } from '../components/visualize/AvailabilityChart'
import { useLocation } from 'react-router-dom'


export const PilotMainTab = () => {
    const {
        desire_position,
        isExtended,
        filtersPt,
        createDimensionsFilters,
        getHashingId, firstReloadPt,
        firstReloadMTPt, setFirstReloadMTPt,
        selectedFiltersPt, setSelectedFiltersPt,
        getSourceStatus,
        handleMultiselect,
        getAccessTokenSilently,
        priceEtaMinPt, setPriceEtaMinPt,
        distanceBucketPriceEtaMinPt, setDistanceBucketPriceEtaMinPt,
        priceDistanceVehiclePt, setPriceDistanceVehiclePt,
        etaTimeVehiclePt, setEtaTimeVehiclePt,
        allRecordCountVsTimePt, setAllRecordCountVsTimePt,
        priceTimeVehiclePt, setPriceTimeVehiclePt
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname

    const getDistanceBucketPriceEtaMinData = async (selectedFiltersPt, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFiltersPt })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFiltersPt.date_range, distanceBucketPriceEtaMinPt.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReloadPt, true)
            if (res_json === null || res_json === undefined) return;

            const sortedData = customSort(parseData(res_json), distanceBucketPriceEtaMinPt.data.series_field, "ASC")

            let rows = []
            let temp = {}

            for (let ele of sortedData) {
                if (!(ele[distanceBucketPriceEtaMinPt.data.series_field] in temp)) {
                    temp[ele[distanceBucketPriceEtaMinPt.data.series_field]] = {}
                    temp[ele[distanceBucketPriceEtaMinPt.data.series_field]] = { [distanceBucketPriceEtaMinPt.data.series_field]: ele[distanceBucketPriceEtaMinPt.data.series_field] }
                }
                temp[ele[distanceBucketPriceEtaMinPt.data.series_field]][`${ele.distance_bucket}_upfront_fare`] = ele.upfront_fare
                temp[ele[distanceBucketPriceEtaMinPt.data.series_field]][`${ele.distance_bucket}_vehicle_eta`] = ele.vehicle_eta

            }

            let i = 0
            for (let key in temp) {
                temp[key]["key"] = i
                rows.push(temp[key])
                i += 1
            }

            let columns = [
                { title: "Vehicle Category", dataIndex: distanceBucketPriceEtaMinPt.data.series_field, key: distanceBucketPriceEtaMinPt.data.series_field, width: 150 },
                { title: "Short", children: [{ title: "Price", dataIndex: "short_upfront_fare", key: "short_upfront_fare" }, { title: "ETA", dataIndex: "short_vehicle_eta", key: "short_vehicle_eta" }] },
                { title: "Medium", children: [{ title: "Price", dataIndex: "medium_upfront_fare", key: "medium_upfront_fare" }, { title: "ETA", dataIndex: "medium_vehicle_eta", key: "medium_vehicle_eta" }] },
                { title: "Long", children: [{ title: "Price", dataIndex: "long_upfront_fare", key: "long_upfront_fare" }, { title: "ETA", dataIndex: "long_vehicle_eta", key: "long_vehicle_eta" }] }
            ]

            setDistanceBucketPriceEtaMinPt((prev) => ({ ...prev, id: hashId, data: { ...prev.data, rows: rows, columns: columns } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    const getChartData = async (selectedFiltersPt, useDataHook, setDataHook, axiosToken) => {
        try {
            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFiltersPt })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFiltersPt.date_range, useDataHook.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReloadPt, true)
            if (res_json === null || res_json === undefined) return;

            const sortedData = customSort(customSort(parseData(res_json), useDataHook.data.dimensions.series_field, "ASC"), useDataHook.data.dimensions.x.field, "ASC")

            setDataHook((prev) => ({ ...prev, id: hashId, data: { ...prev.data, chart_data: sortedData } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    useEffect(() => {
        if (selectedFiltersPt.id !== priceEtaMinPt.id) {

            if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setSpinning(() => true)

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getSourceStatus({ currentTab: current_tab, sourceStatusHook: allRecordCountVsTimePt, setSourceStatusHook: setAllRecordCountVsTimePt, axiosToken: axiosToken, isFirstReload: firstReloadPt, selectedFiltersHook: selectedFiltersPt, isPilot: true }),
                        getDistanceBucketPriceEtaMinData(selectedFiltersPt, axiosToken),
                        getChartData(selectedFiltersPt, priceEtaMinPt, setPriceEtaMinPt, axiosToken),
                        getChartData(selectedFiltersPt, priceDistanceVehiclePt, setPriceDistanceVehiclePt, axiosToken),
                        getChartData(selectedFiltersPt, etaTimeVehiclePt, setEtaTimeVehiclePt, axiosToken),
                        getChartData(selectedFiltersPt, priceTimeVehiclePt, setPriceTimeVehiclePt, axiosToken)
                    ])
                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadMTPt(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFiltersPt])

    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return selectedFiltersPt?.date_range?.startDate ? <DateRange key={ index } selectedFilterHook={selectedFiltersPt} setSelectedFilterHook={setSelectedFiltersPt} /> : <Dummy key={ index } value={ key } />
                        } else {
                            let option = filtersPt.find(option => option.key.value === key);
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <div className="chart_container2">
                        <AvailabilityChart data={ allRecordCountVsTimePt.data } firstRender={ firstReloadMTPt } />
                    </div>
                    <div className='chart_container' style={ { width: "100%", display: "flex", gap: "15px" } }>
                        <div style={ { width: "50%", height: "100%" } } >
                            <ScatterCharts data={ priceEtaMinPt.data } firstRender={ firstReloadMTPt } />
                        </div>
                        <div style={ { width: "50%", height: "100%" } } >
                            <DataTable data={ distanceBucketPriceEtaMinPt.data } className={"half_table"} firstRender={ firstReloadMTPt } />
                        </div>
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ priceDistanceVehiclePt.data } firstRender={ firstReloadMTPt } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ etaTimeVehiclePt.data } firstRender={ firstReloadMTPt } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ priceTimeVehiclePt.data } firstRender={ firstReloadMTPt } />
                    </div>
                </div>
            </div>
        </div>
    )
}