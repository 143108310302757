import React, { useEffect, useState } from 'react'
import "./styles/Charts.css"

export const ScrollableLegend = ({ datasets, chartEl, forceUpdate }) => {

  const [legendStatus, setLegendStatus] = useState(() => {
    let temp = {}
    for (let ind = 0; ind < datasets.length; ind++) {
      temp[ind] = { isHidden: false, isFocused: false }
    }
    return temp
  })


  const handleHiddenClick = (datasetIndex) => {
    setLegendStatus((prev) => ({ ...prev, [datasetIndex]: { ...prev[datasetIndex], isHidden: !prev[datasetIndex]["isHidden"] } }))

    chartEl.current.getDatasetMeta(datasetIndex).hidden = chartEl.current.getDatasetMeta(datasetIndex).hidden === null ? true : !chartEl.current.getDatasetMeta(datasetIndex).hidden;
    chartEl.current.update()
    forceUpdate({});
  }

  const handleFocusedClick = (datasetIndex) => {
    let updatedLegendStatus;

    if (legendStatus[datasetIndex]["isFocused"]) {
      updatedLegendStatus = Object.fromEntries(Object.entries(legendStatus).map(([datasetIndex, values]) => ([datasetIndex, { isHidden: false, isFocused: false }])));
    } else {
      updatedLegendStatus = Object.fromEntries(Object.entries(legendStatus).map(([datasetIndex, values]) => ([datasetIndex, { isHidden: true, isFocused: false }])));
      updatedLegendStatus[datasetIndex] = { isHidden: false, isFocused: true }
    }
    setLegendStatus(updatedLegendStatus);

    for (let key in updatedLegendStatus) {
      const isHidden = updatedLegendStatus[key]["isHidden"]
      chartEl.current.getDatasetMeta(key).hidden = isHidden;
    }

    chartEl.current.update()
    forceUpdate({});
  }

  useEffect(()=>{
    setLegendStatus(() => {
      let temp = {}
      for (let ind = 0; ind < datasets.length; ind++) {
        temp[ind] = { isHidden: false, isFocused: false }
      }
      return temp
    })
  }, [datasets.length])

  return (
    <>
      <div className='customLegend'>
        { datasets.map((series, index) => {
          return (<li key={`${series}-${index}`}><span style={ { opacity: legendStatus[index]?.["isHidden"] ? "0.3" : "1" } } onClick={ () => handleHiddenClick(index) }><span style={ { backgroundColor: legendStatus[index]?.["isHidden"] ? "grey" : series.borderColor } }></span><p>{ series.label }</p></span><span><img src='aim.png' alt="aim" onClick={ () => handleFocusedClick(index) } /></span></li>)
        }) }
      </div>
    </>
  )
}
