import { Config } from "../config/Config"


export const exportingTab = ({ label, headers, payload }) => {
  return (
    `
      <!DOCTYPE html>
      <html lang="en">

      <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
          <title>Export : ${label}</title>
          <style>
              body {
                  background-color: #f4f4f4;
                  flex-direction: column;
                  font-family: 'Open Sans', sans-serif;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100vh;
                  margin: 0;
              }

              .loader-wrapper {
                  display: none;
                  text-align: center;
                  width: 100%;
              }

              .error_info {
                  display: none;
              }

              .success_info {
                  display: none;
              }

              .progress-wrapper {
                  display: none;
              }

              .progress_info {
                  background-color: gray;
                  border-radius: 20px;
                  margin: 15px 15px;
                  height: 30px;
                  width: 340px;
                  position: relative;
              }
      
              .progress-percent {
                  position: absolute;
                  top: 5px;
                  left: 45%;
                  color: #ffffff;
              }

              .error_info .message {
                  border-left-color: #d9534f;
                  background-color: rgba(217, 83, 79, 0.1);
              }

              .success_info .message {
                  border-left-color: #34bc36;
                  background-color: rgba(71, 234, 74, 0.1);
              }

              .progress-wrapper .message {
                  border-left-color: #f0ad4e;
                  background-color: rgba(240, 173, 78, 0.1);
              }

              .loader-wrapper .message {
                  border-left-color: #5bc0de;
                  background-color: rgba(91, 192, 222, 0.1);
              }

              .progress-done {
                  background: linear-gradient(to left, #F2709C, #FF9472);
                  box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
                  border-radius: 20px;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  width: 0;
                  opacity: 1;
                  transition: 1s ease 0.3s;
              }


              .info_show {
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
              }

              .info_show>div {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  text-align: center;
              }


              .loader-wrapper span {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #000;
                  opacity: 0;
                  animation: loading 3.3s infinite ease-in-out;
              }

              .message {
                  font-size: 18px;
                  width: 320px;
                  background-color: #ffffff;
                  padding: 1px 20px;
                  border: 1px solid #eee;
                  border-left-width: 5px;
                  border-radius: 3px;
                  margin: 10px auto;
                  font-family: 'Open Sans', sans-serif;
                  font-size: 16px;
                  text-align: left;
                  margin-bottom: 30px;
              }

              .btn {
                  background-color: DodgerBlue;
                  border: none;
                  border-radius: 5px;
                  color: white;
                  cursor: pointer;
                  font-size: 17px;
                  font-weight: 550;
                  padding: 12px 30px;
                  text-decoration: none;
              }

              .btn:hover {
                  background-color: RoyalBlue;
              }

              .loader-wrapper span:nth-of-type(1) {
                  animation-delay: 1000ms;
              }

              .loader-wrapper span:nth-of-type(2) {
                  animation-delay: 800ms;
              }

              .loader-wrapper span:nth-of-type(3) {
                  animation-delay: 600ms;
              }

              .loader-wrapper span:nth-of-type(4) {
                  animation-delay: 400ms;
              }

              .loader-wrapper span:nth-of-type(5) {
                  animation-delay: 200ms;
              }

              @keyframes loading {
                  0% {
                      opacity: 0;
                      transform: translateX(-300px);
                  }

                  33% {
                      opacity: 1;
                      transform: translateX(0px);
                  }

                  66% {
                      opacity: 1;
                      transform: translateX(0px);
                  }

                  100% {
                      opacity: 0;
                      transform: translateX(300px);
                  }
              }
          </style>
      </head>

      <body>
          <div class="info_show">
              <div class="loader-wrapper">
                  <div class="message">
                      <p>Initiating the export process.</p>
                  </div>
                  <span></span><span></span><span></span><span></span><span></span><span></span>
              </div>
              <div class="success_info">
                  <div class="message">
                      <p>File has been successfully exported.</p>
                      <p>Click the button below to download the file</p>
                  </div>
                  <a class="btn download" download="true"><i class="fa fa-download"></i> &nbsp; Click To Download</a>
              </div>
              <div class="error_info">
                  <div class="message">
                      <p>Something Went Wrong.</p>
                  </div>
                  <button onclick="reconnectWithStatusCheck()" class="btn reconnect"><i class="fa fa-rotate-right"></i> &nbsp; Re-connect</button>
              </div>
              <div class="progress-wrapper">
                  <div class="message">
                      <p class="msg">Exporting in progress...</p>
                      <p>This may take a moment. Please wait.</p>
                  </div>
                  <div class="progress_info">
                      <div class="progress-done"></div>
                      <div class="progress-percent"></div>
                  </div>
              </div>
          </div>
      </body>
      <script>

          function showInfo(className) {
              let classNameList = [".success_info", ".error_info", ".progress-wrapper", ".loader-wrapper"]

              for (let class_name of classNameList) {

                  if (class_name === className) {
                      document.querySelector(class_name).style.display = "block"
                  } else {
                      document.querySelector(class_name).style.display = "none"
                  }
              }
          }

          async function initiateExporting() {
              console.log("payload", ${payload})
              try {
                  const response = await fetch("${Config.apiUrl}/downloadFast", {
                      method: "POST",
                      headers: ${JSON.stringify(headers)},
                      body: ${JSON.stringify(payload)}
                  });

                  if (response.ok) {
                      const json_response = await response.json()
                      if (json_response?.requestId) {

                          document.querySelector(".reconnect").setAttribute("data-id", json_response.requestId)

                          return json_response.requestId
                      }
                  }

                  showInfo(".error_info")
                  document.querySelector(".reconnect").style.display = "none"
                  console.error("Response Error initiating export:", response.statusText);
              } catch (error) {
                  showInfo(".error_info")
                  document.querySelector(".reconnect").style.display = "none"
                  console.error("Request Error initiating export:", error);
              }
          }

          async function statusCheck(requestId) {
              try {
                  let isCompleted = false;

                  while (!isCompleted) {
                      const response = await fetch("${Config.apiUrl}/downloadStatus?requestId=" + requestId, {
                          method: "GET",
                          headers: ${JSON.stringify(headers)},
                      });

                      let responseData = await response.json();

                      if (response.ok) {
                          isCompleted = responseData.isCompleted;

                          if (isCompleted) {
                              showInfo(".success_info")
                              document.querySelector(".download").setAttribute("href", responseData.presignedUrl);
                          } else {
                              showInfo(".progress-wrapper")
                              const progressPercent = responseData.progress;
                              document.querySelector(".progress-done").style.width = progressPercent + "%";
                              document.querySelector(".progress-percent").textContent = progressPercent + "%";

                              if (progressPercent > 98) {
                                document.querySelector(".msg").textContent = "Almost there! Data is being compiled...";
                              }
                          }

                          await new Promise(resolve => setTimeout(resolve, 3000));

                      } else {
                          isCompleted = true;

                          showInfo(".error_info")
                          console.error("Error", responseData?.error ? responseData.error : responseData);
                      }
                  }
              } catch (error) {
                  isCompleted = true;

                  showInfo(".error_info")
                  console.error("ErrorCaught:", error);
              }
          }

          async function startTheExportingProcess() {
              showInfo(".loader-wrapper")

              let requestId = await initiateExporting()

              if (requestId) {
                  showInfo(".progress-wrapper")

                  document.querySelector(".progress-done").style.width = "0%";
                  document.querySelector(".progress-percent").textContent = "0%";

                  await statusCheck(requestId)
              }

          }

          async function reconnectWithStatusCheck() {
              document.querySelector(".progress-done").style.width = "0%";
              document.querySelector(".progress-percent").textContent = "0%";

              const requestId = document.querySelector(".reconnect").getAttribute("data-id")

              await statusCheck(requestId)
          }

          startTheExportingProcess();
      </script>
      </html>
  `
  )
}
