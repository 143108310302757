import React from 'react';
import { Column } from '@ant-design/plots';
import { Heading } from '../label/Heading';
import { NoDataError } from './NoDataError';

let color_mapping = {
    "short": '#01B6CA',
    "medium": '#F10196',
    "long": "#F66C03"
}

export const GroupBarCharts = ({ data, firstRender }) => {

    const colors = [...new Set(data.rows.map(ele => ele[data.seriesField]))].map((series)=> color_mapping[series])

    const config = {
        data: data.rows,
        isGroup: true,
        appendPadding: 10,
        xField: data.xField,
        yField: data.yField,
        seriesField: data.seriesField,
        color: colors,
        label: {
            position: 'bottom',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' }
            ],
        },
        legend: {
            layout: 'horizontal',
            position: 'top-left',
            itemName: { style: { fill: '#333' } },
            title: null,
            spacing: 10
        }
    };

    return (
        <>
            <Heading label={ data.label } fields={ data.fields } />
            { data.rows.length > 0 ? <Column { ...config } /> : <NoDataError firstRender={firstRender} /> }
        </>
    );
};

