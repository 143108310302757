// AuthWrapper.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import loaderStyles from "../components/Loader/Loading.module.css"
import loginStyles from "./Styles.module.css"

export const Authentication = ({ children }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    if (isLoading) {
        return (
            <div style={ { width: "100vw", height: "95vh", position: "relative" } }>
                <div className={ loaderStyles.loading } style={ { width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px" } }>
                    <svg version="1.1" id="L6" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
                        <rect fill="none" stroke="#000" strokeWidth="4" x="25" y="25" width="50" height="50">
                            <animateTransform
                                attributeName="transform"
                                dur="0.5s"
                                from="0 50 50"
                                to="180 50 50"
                                type="rotate"
                                id="strokeBox"
                                attributeType="XML"
                                begin="rectBox.end"
                            />
                        </rect>
                        <rect x="27" y="27" fill="#000" width="46" height="50">
                            <animate
                                attributeName="height"
                                dur="1.3s"
                                attributeType="XML"
                                from="50"
                                to="0"
                                id="rectBox"
                                fill="freeze"
                                begin="0s;strokeBox.end"
                            />
                        </rect>
                    </svg>
                </div>
            </div>
        );
    }

    return isAuthenticated ? <>{ children }</> : (
        <div className={ loginStyles.login_page }>
            <div className={ loginStyles.display }>
                <div >
                    <img src='logo.png' alt='logo' />
                    <h1><span>Project Motor</span></h1>
                </div>
                <div>
                    <h1 >Login To View Dashboard</h1>
                    <button onClick={ () => loginWithRedirect() } className={ loginStyles.button }>{ "Click To Login" }</button>
                </div>
            </div>
        </div>
    );
};

