import { uniqueList } from '../utils/uniqueList';
import { apiService } from './apiService';

const custom_filter = [
    { key: { label: "Week", value: "day_category" }, options: [{ label: "Weekday", value: "Weekday" }, { label: "Weekend", value: "Weekend" }] },
    { key: { label: "Timeframe", value: "timeframe" }, options: [{ label: "DATE-HOUR", value: "HOUR" }, { label: "DATE", value: "DAY" }, { label: "WEEK", value: "WEEK(MONDAY)" }, { label: "MONTH", value: "MONTH" }, { label: "QUARTER", value: "QUARTER" }, { label: "YEAR", value: "YEAR" }], default: { options: [{ label: "DATE-HOUR", value: "HOUR" }], negation: false }, mode: "single" },
    { key: { label: "ETA", value: "eta_available" }, options: [{ label: "ETA Available", value: true }, { label: "ETA Not Available", value: false }] },
]

export const FetchFilteredData = async (selectedFilters, dateRange, fields, getAccessTokenSilently, cancelTokenSource, completeFields, firstReload, isPilot) => {

    const dimensionsFilters = Object.entries(selectedFilters).map(([fieldName, payload]) => ({ fieldName: fieldName, values: payload.options.map((ele) => ele.value), negation: payload.negation }));

    const accessToken = await getAccessTokenSilently()

    const response = await apiService.post('/filters',
        {
            dateRange: dateRange,
            dimensionsFilters: dimensionsFilters,
            fields: fields,
            firstReload: firstReload,
            completeFields: completeFields,
            isPilot: isPilot
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
            , cancelToken: cancelTokenSource.token
        }
    );
    const json_response = uniqueList(response.data)

    const data = json_response.concat(custom_filter).map(item => {
        let key = item.key.value
        if (key !== "timeframe") {
            item.options.sort((a, b) => {
                const nameA = a.label.toUpperCase();
                const nameB = b.label.toUpperCase();

                if (nameA < nameB) { return -1 }
                if (nameA > nameB) { return 1 }
                return 0;
            })
        }

        if (key === "surge_multiplier_category"){
            item["key"]["label"] = "Surge Multiplier"
        }

        if (selectedFilters && selectedFilters?.[key]) {

            return {
                ...item,
                default: {
                    options: selectedFilters[key].options.map((ele) => {
                        let option = item.options.find((item_option) => item_option.value === ele.value)
                        return option !== undefined ? option : ele
                    }),
                    negation: selectedFilters[key].negation
                }
            };
        } else if (item?.default === undefined) {

            return {
                ...item,
                default: { options: item.options, negation: false }
            }
        }

        return item
    });

    return data;

}