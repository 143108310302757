import React, { useContext, useEffect, useRef, useState } from 'react'
import { BarCharts } from '../components/visualize/BarCharts'
import { DataContext } from '../context/ContextProvider'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { Loading } from '../components/Loader/Loading'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { FetchStatsData } from '../services/FetchStatsData'
import { MultiSelect } from '../components/select/MultiSelect'
// import { MultiSelect } from '../components/select/MultiSelect'

export const UserStatsTab = () => {
    const {
        isExtended,
        getAccessTokenSilently,
        getHashingId,
        userStatsFilters, 
        userStatsSelectedFilters, setUserStatsSelectedFilters,
        firstReloadUS, setFirstReloadUS,
        userStatsData, setUserStatsData,
        handleMultiselect
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname


    const getData = async (hashId, allCities, axiosToken) => {
        try {
            let cityList = []

            if (userStatsSelectedFilters.data?.city){
                if (userStatsSelectedFilters.data.city.negation){
                    let excludedCity = userStatsSelectedFilters.data.city.options.map(ele => ele.value)
                    let allCityList = userStatsFilters.find((dict => dict.key.value === "city")).options.map(ele => ele.value)

                    cityList = allCityList.map(city => !excludedCity.includes(city))
                } else {
                    cityList = userStatsSelectedFilters.data.city.options.map(ele => ele.value)
                }
            }

            let countryList = []

            if (userStatsSelectedFilters.data?.country_code_currency){
                if (userStatsSelectedFilters.data.country_code_currency.negation){
                    let excludedCountry = userStatsSelectedFilters.data.country_code_currency.options.map(ele => ele.value)
                    let allCountryList = userStatsFilters.find((dict => dict.key.value === "country_code_currency")).options.map(ele => ele.value)

                    countryList = allCountryList.map(country => !excludedCountry.includes(country))
                } else{
                    countryList = userStatsSelectedFilters.data.country_code_currency.options.map(ele => ele.value)
                }
            }


            let res_json = await FetchStatsData({ dateRange: userStatsSelectedFilters.date_range, countryList: countryList, cityList: cityList, getAccessTokenSilently: getAccessTokenSilently, cancelTokenSource: axiosToken })
            if (res_json === null || res_json === undefined) return

            // let data = res_json.map(ele => {
            //     let city = allCities.options.find(option => option.value === ele.city)

            //     if (ele.count === 0 || ele?.user_email ? ele.user_email.includes("anakin.company"): false) {
            //         return { city: city ? city.label : ele.city, user_email: null, count: null }
            //     }
            //     return { ...ele, city: city ? city.label : ele.city };
            // });

            // const addOnData = allCities.options.map(ele => ({ city: ele.label, user_email: null, count: null }))

            // const uniqueSet = new Set();
            // const uniqueData = data.concat(addOnData).filter((item) => {
            //     const key = `${item.city}_${item.user_email}_${item.count}`;
            //     if (!uniqueSet.has(key)) {
            //         uniqueSet.add(key);
            //         return true;
            //     }
            //     return false;
            // });

            setUserStatsData((prev) => ({ ...prev, id: hashId, data: { ...prev.data, chart_data: res_json } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message)
                return false;
            } else {
                console.error(error)
                throw new Error(error)
            }
        }
    }

    useEffect(() => {

        const handleUseEffect = async (allCities) => {
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: userStatsSelectedFilters })

            if (hashId !== userStatsData.id) {

                if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
                let axiosToken = axios.CancelToken.source()
                timeoutId.current = axiosToken

                setTimeout(async () => {
                    try {
                        let results = await Promise.all([
                            getData(hashId, allCities, axiosToken)
                        ])
                        if (!(results.includes(false))) setSpinning(() => false)
                        setFirstReloadUS(false)
                    } catch (error) {
                        console.error(error)
                        throw new Error("Something Went Wrong")
                    }
                })
            } else {
                setSpinning(() => false)
            }
        }

        let allCities = userStatsFilters.find(option => option.key.value === "city");

        if (!(allCities)) return

        setSpinning(() => true)
        handleUseEffect(allCities)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStatsSelectedFilters, userStatsFilters])


    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    <DateRange  selectedFilterHook={userStatsSelectedFilters} setSelectedFilterHook={setUserStatsSelectedFilters} />
                    { userStatsFilters.map(option => {
                        if (option.key.value === "country_code_currency" || option.key.value === "city") {
                            return (
                                <MultiSelect key={ option.key.value } data={ option } handleFunc={ handleMultiselect } />
                            );
                        }
                        return null;
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <>
                        <BarCharts data={ userStatsData.data } firstRender={ firstReloadUS } />
                    </>
                </div>
            </div>
        </div>
    )
}
