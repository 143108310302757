import React from 'react'

export const NoDataError = ({firstRender}) => {

    return (
        <div className='no_data'>
            { firstRender ? <>
                <img className='loading_gif' src='loading.gif' alt='Loading Data' />
                <p>Loading data</p>
            </> : <>
                <img src='no_data.png' alt='No Data' />
                <p>No Common Routes Found For Selected Filters</p>
            </> }
        </div>
    )
}
