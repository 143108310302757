import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/Page.css'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { GroupBarCharts } from '../components/visualize/GroupBarCharts'
import { FetchRenderData } from '../services/FetchRenderData'
import { Loading } from '../components/Loader/Loading'
import { LineCharts } from '../components/visualize/LineCharts'
import { customSort } from '../utils/customSort'
import { parseData } from '../utils/parseData'
import axios from 'axios'
import { useLocation } from 'react-router-dom'


export const TechnicalTab = () => {
    const {
        desire_position,
        isExtended,
        createDimensionsFilters,
        getHashingId, firstReload,
        firstReloadTT, setFirstReloadTT,
        filters, selectedFilters, setSelectedFilters,
        getSourceStatus,
        handleMultiselect,
        getAccessTokenSilently,
        tripDistanceRecord, setTripDistanceRecord,
        recordCountVsTime, setRecordCountVsTime,
        allRecordCountVsTime, setAllRecordCountVsTime
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname


    const getRecordCountVsTimeData = async (selectedFilters, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, recordCountVsTime.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            if (res_json === null || res_json === undefined) return

            let sorted_data = customSort(customSort(parseData(res_json), "source", "ASC"), "local_scraped_at_timestamp", "ASC")

            setRecordCountVsTime((prev) => ({
                ...prev,
                id: hashId,
                data: {
                    ...prev.data,
                    chart_data: sorted_data
                }
            }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }

    const getTripDistanceRecordData = async (selectedFilters, axiosToken) => {
        try {
            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, tripDistanceRecord.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            if (res_json === null || res_json === undefined) return

            const dataArray = parseData(res_json)

            const sortedData = dataArray.sort((a, b) => {
                const sourceComparison = a.source.localeCompare(b.source);
                if (sourceComparison !== 0) { return sourceComparison }
                return ["short", "medium", "long"].indexOf(a.distance_bucket) - ["short", "medium", "long"].indexOf(b.distance_bucket);
            });

            setTripDistanceRecord((prev) => ({
                ...prev,
                id: hashId,
                data: {
                    ...prev.data,
                    rows: sortedData
                }
            }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    useEffect(() => {
        if (selectedFilters.id !== tripDistanceRecord.id) {

            if (timeoutId.current) { timeoutId.current.cancel("Previous request canceled") };
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setSpinning(() => true)

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getSourceStatus({ currentTab: current_tab, sourceStatusHook: allRecordCountVsTime, setSourceStatusHook: setAllRecordCountVsTime, axiosToken: axiosToken, isFirstReload: firstReload, selectedFiltersHook: selectedFilters, allowedAll: true }),
                        getRecordCountVsTimeData(selectedFilters, axiosToken),
                        getTripDistanceRecordData(selectedFilters, axiosToken)
                    ])

                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadTT(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return selectedFilters ? <DateRange key={ index } selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} /> : <Dummy key={ index } value={ key } />
                        } else {
                            let option = filters.find(option => option.key.value === key)
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <div className="chart_container">
                        <LineCharts data={ allRecordCountVsTime.data } firstRender={ firstReloadTT } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ recordCountVsTime.data } firstRender={ firstReloadTT } />
                    </div>
                    <div className="chart_container">
                        <GroupBarCharts data={ tripDistanceRecord.data } firstRender={ firstReloadTT } />
                    </div>
                </div>
            </div>
        </div>
    )
}