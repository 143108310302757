import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/Page.css'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { FetchRenderData } from '../services/FetchRenderData'
import { titleCase } from '../utils/titleCase'
import { Loading } from '../components/Loader/Loading'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { MyTable } from '../components/visualize/MyTable'

const naming_convention = {
    "local_scraped_at_timestamp": { key: "Timestamp" },
    "country_code_currency": { key: "Country" },
    "source": { key: "Rival" },
    "pickup_zone": { key: "Pickup Zone" },
    "drop_zone": { key: "Drop Zone" },
    "source_vehicle_category": { key: "Category" },
    "distance_bucket": { key: "Bucket" },
    "vehicle_eta": { key: "Search ETA in min" },
    "upfront_fare": { key: "Search Price" },
    "surge_multiplier": { key: "Surge" },
    "base_fare": { key: "Base Fare" },
    "minimum_charge": { key: "Minimum Fare" },
    "per_distance_charge": { key: "Price per KM" },
    "per_minute_ride_charge": { key: "Price per Min." },
    "per_minute_wait_charge": { key: "Waiting Fee" },
    "route_distance": { key: "Distance in KM" },
    "cancellation_charge": { key: "Cancellation Fee" },
    "high_demand_fee": { key: "High Demand Fee" }
}


export const DownloadTab = () => {
    const {
        desire_position,
        isExtended,
        createDimensionsFilters,
        getHashingId, firstReload_,
        getAccessTokenSilently,

        firstReloadDT, setFirstReloadDT,
        downloadTabSelectedFilters, setDownloadTabSelectedFilters,
        downloadTabFilters,
        downloadTabData, setDownloadTabData,
        handleMultiselect
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname

    const getData = async (downloadTabSelectedFilters, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: downloadTabSelectedFilters })

            let res_json = await FetchRenderData(dimensionsFilters, downloadTabSelectedFilters.date_range, downloadTabData.data.fields, getAccessTokenSilently, true, 1, 200, axiosToken, firstReload_)
            if (res_json === null || res_json === undefined) return;

            let rows = []
            for (let i = 0; i < res_json[0].values.length; i++) {
                let row = []
                for (let ele of res_json) {
                    let temp = typeof ele.values[i] === 'number' ? 
                                    Number.isInteger(ele.values[i]) ? ele.values[i] 
                                        : ele.values[i].toFixed(2) : ele.values[i] === null ? "" 
                                            : ele.key === "local_scraped_at_timestamp" ? ele.values[i].split("+")[0] : 
                                                ele.key === "country_code_currency"? ele.values[i] : titleCase(ele.values[i])
                    row.push(temp)
                }
                rows.push(row)
            }

            const columns = []
            for (let ele of res_json) {
                let temp = { label:  ele.key in naming_convention ? naming_convention[ele.key].key : titleCase(ele.key), value: ele.key}
                columns.push(temp)
            }


            setDownloadTabData(prev => ({
                ...prev,
                id: hashId,
                data: {
                    ...prev.data,
                    rows: rows,
                    columns: columns
                }
            }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    useEffect(() => {

        if (downloadTabSelectedFilters.id !== downloadTabData.id) {
            setSpinning(() => true)

            if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getData(downloadTabSelectedFilters, axiosToken)
                    ])
                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadDT(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadTabSelectedFilters])


    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return <DateRange key={ index } selectedFilterHook={downloadTabSelectedFilters} setSelectedFilterHook={setDownloadTabSelectedFilters} />
                        } else {
                            let option = downloadTabFilters.find(option => option.key.value === key);
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") ) {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    {/* <code>{JSON.stringify(downloadTabSelectedFilters)}</code> */}
                    <Loading spinning={ spinning } />
                    <div className='dataTable'>
                        <MyTable data={ downloadTabData.data } firstRender={ firstReloadDT } />
                    </div>
                    <span >--- Showing Only First 200 Rows Of The Dataset ---</span>
                </div>
            </div>
        </div>
    )
}
