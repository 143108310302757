import React, { useContext, useEffect, useState } from 'react';
import { Heading } from '../label/Heading';
import { NoDataError } from './NoDataError';
import "./styles/Charts.css";
import { DataContext } from '../../context/ContextProvider';

const groupFields = ["local_scraped_at_timestamp", "country_code_currency", "city", "pickup_zone", "drop_zone", "source", "source_vehicle_category", "segment", "route_type", "distance_bucket", "distance_range", "surge_multiplier_category"]
const countFields = ["surged_record_count", "non_surged_record_count"]

export const MyTable = ({ data, firstRender }) => {

    const { downloadTabHiddenFields, setDownloadTabHiddenFields } = useContext(DataContext)
    const [rows, setRows] = useState(data.rows)

    useEffect(() => {
        setRows(() => {
            if (downloadTabHiddenFields.length === 0) return data.rows

            let tempRows = [];

            const groupedData = data.rows.reduce((acc, row) => {

                const key = groupFields.map(key => downloadTabHiddenFields.includes(key) ? "" : row[data.columns.findIndex((ele) => ele.value === key)]).join("-");

                if (!acc[key]) acc[key] = [];

                acc[key].push(row);

                return acc;
            }, {});

            for (let key in groupedData) {
                let tempRow = [];

                for (let i = 0; i < groupedData[key].length; i++) {
                    for (let j = 0; j < groupedData[key][i].length; j++) {
                        if (groupFields.includes(data.columns[j].value)) {
                            tempRow[j] = groupedData[key][i][j]
                        } else if (countFields.includes(data.columns[j].value)) {
                            if (tempRow[j] === undefined) tempRow[j] = 0

                            tempRow[j] += (groupedData[key][i][j] === null || groupedData[key][i][j] === "") ? 0 : groupedData[key][i][j]
                        } else {
                            if (tempRow[j] === undefined) tempRow[j] = [0, 0]
                            tempRow[j][0] += (groupedData[key][i][j] === null || groupedData[key][i][j] === "") ? 0 : +groupedData[key][i][j]
                            tempRow[j][1] += (groupedData[key][i][j] === null || groupedData[key][i][j] === "") ? 0 : 1
                        }
                    }
                }

                let row = tempRow.map((ele, ind) => downloadTabHiddenFields.includes(data.columns[ind].value) ? "" : (countFields.includes(data.columns[ind].value) || groupFields.includes(data.columns[ind].value)) ? ele : ele[0] === 0 ? "" : Number.isInteger(ele[0] / ele[1]) ? ele[0] / ele[1] : (ele[0] / ele[1]).toFixed(2))

                tempRows.push(row)
            }

            return tempRows;
        })

    }, [downloadTabHiddenFields, data])

    return (
        <div className="table_container">
            <Heading label={ data.label } fields={ data.fields.filter((field) => !downloadTabHiddenFields.includes(field)) } />
            { downloadTabHiddenFields.length === 0 ?
                <div style={ { textAlign: "center", margin: "15px 0", fontWeight: "600" } }>
                    Click on a table header to remove the column; removed columns appear above the table. Click on a removed column to restore it to the table.
                </div>
                : <div className='keyGroupTab'>
                    { downloadTabHiddenFields.map((key, index) => <span
                        key={key}
                        className='bubble'
                        style={ { backgroundColor: groupFields.includes(key) ? "#ffffff" : "#D8D8D9"} }
                        onClick={ () => setDownloadTabHiddenFields((prev) => prev.filter((ele) => ele !== key)) } >
                        { data.columns.find(ele => ele.value === key).label }
                    </span>) }
                </div> }
            { rows.length > 0 ? <div style={ { overflowY: "auto", maxHeight: data.height, borderRadius: "8px 8px 0 0" } }>
                <table className="myDynamicTable">
                    <thead>
                        <tr>{ data.columns.map((column, ind) => (!downloadTabHiddenFields.includes(column.value) && <th
                            key={ `head_${ind}` }
                            style={ { color: "white", cursor: "pointer" } }
                            onClick={ () => setDownloadTabHiddenFields((prev) => prev.includes(column.value) ? prev.filter((ele) => ele !== column.value) : [...prev, column.value]) } >
                            { column.label }
                        </th>)) }</tr>
                    </thead>
                    <tbody>{
                        rows.map((row, i) => <tr key={ `row_${i}` }>{
                            row.map((value, j) => (!downloadTabHiddenFields.includes(data.columns[j].value) && <td key={ `value_${j}` } >{ value }</td>))
                        }</tr>)
                    }</tbody>
                </table>
            </div> : <NoDataError firstRender={ firstRender } /> }
        </div>
    )
}
