export const titleCase = (str) => {

    str = str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    });

    str = str.join(' ');

    str = str.split('_').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    });

    str = str.join(' ');

    str = str.split('-').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    });

    return str.join('-');
}
