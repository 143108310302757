import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/Page.css'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { DataTable } from '../components/visualize/DataTable'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { FetchRenderData } from '../services/FetchRenderData'
import { titleCase } from '../utils/titleCase'
import { Loading } from '../components/Loader/Loading'
import axios from 'axios'
import { AvailabilityChart } from '../components/visualize/AvailabilityChart'
import { useLocation } from 'react-router-dom'


const naming_convention = {
    "country_code_currency": { key: "Country" },
    "source": { key: "Players" },
    "route_type": { key: "Route" },
    "drop_zone": { key: "Drop Zone" },
    "source_vehicle_category": { key: "Category" },
    "vehicle_eta": { key: "Search ETA" },
    "upfront_fare": { key: "Search Price" },
    "minimum_charge": { key: "Minimum Fare" },
    "per_distance_charge": { key: "Price per KM" },
    "per_minute_ride_charge": { key: "Price per Min." },
    "per_minute_wait_charge": { key: "Waiting Fee" },
    "cancellation_charge": { key: "Cancellation Fee" },
    "high_demand_fee": { key: "High Demand Fee" }
}


export const PricingSummary = () => {
    const { 
        desire_position, 
        isExtended, 
        createDimensionsFilters,
        getHashingId, firstReload,
        firstReloadPT, setFirstReloadPT,
        selectedFilters, setSelectedFilters,
        filters, 
        getAccessTokenSilently, 
        getSourceStatus,
        allRecordCountVsTime, setAllRecordCountVsTime,
        pricingTableData, setPricingTableData, 
        handleMultiselect 
    } = useContext(DataContext)
    
    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname

    const getData = async (selectedFilters, axiosToken) => {
        try {
            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            let res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, pricingTableData.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            if (res_json === null || res_json === undefined) return;

            let rows = []
            for (let i = 0; i < res_json[0].values.length; i++) {
                let temp = { key: i }
                for (let ele of res_json) {
                    temp[ele.key] = ele.key === "local_scraped_at_timestamp" ? ele.values[i].split("+")[0] : ele.values[i]
                }
                rows.push(temp)
            }

            const columns = []
            for (let ele of res_json) {
                let temp = {
                    title: ele.key in naming_convention ? naming_convention[ele.key].key : titleCase(ele.key),
                    dataIndex: ele.key,
                    render: (text) => {
                        if (typeof text === 'number') {
                            if (Number.isInteger(text)) {
                                return <p>{ text }</p>
                            } else {
                                return <p>{ text.toFixed(2) }</p>
                            }
                        } else if (text === null) {
                            return <p></p>
                        } else {

                            return ele.key === "local_scraped_at_timestamp" || ele.key === "country_code_currency" ? <p>{ text }</p> : <p>{ titleCase(text) }</p>
                        }
                    }
                }
                if (ele.key === "source_vehicle_category") {
                    temp["width"] = 100
                }
                columns.push(temp)
            }

            setPricingTableData(prev => ({
                ...prev,
                id: hashId,
                data: {
                    ...prev.data,
                    rows: rows,
                    columns: columns
                }
            }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }

    useEffect(() => {

        if (selectedFilters.id !== pricingTableData.id) {
            setSpinning(() => true)

            if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getSourceStatus({ currentTab: current_tab, sourceStatusHook: allRecordCountVsTime, setSourceStatusHook: setAllRecordCountVsTime, axiosToken: axiosToken, isFirstReload:firstReload, selectedFiltersHook: selectedFilters }),
                        getData(selectedFilters, axiosToken)
                    ])

                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadPT(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return selectedFilters ? <DateRange key={ index } selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} /> : <Dummy key={ index } value={ key } />
                        } else {
                            let option = filters.find(option => option.key.value === key);
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <div className="chart_container2">
                        <AvailabilityChart data={ allRecordCountVsTime.data } firstRender={firstReloadPT} />
                    </div>
                    <div className='dataTable' style={ { marginBottom: "40px" } }>
                        <DataTable data={ pricingTableData.data } firstRender={firstReloadPT} />
                    </div>
                </div>
            </div>
        </div>
    )
}
