export const colorData = [
    {
        Rival: 'Bolt',
        Color: '#15ff00',
    },
    {
        Rival: 'Competitor01',
        Color: '#263238',
    },
    {
        Rival: 'Competitor02',
        Color: '#ffa800',
    },
    {
        Rival: 'Competitor03',
        Color: '#03d0f4',
    },
    {
        Rival: 'Competitor04',
        Color: '#ff5500',
    },
    {
        Rival: 'Competitor05',
        Color: '#0033cc',
    },
    {
        Rival: 'Competitor06',
        Color: '#0072f0',
    },
    {
        Rival: 'Competitor07',
        Color: '#9ac1ab',
    },
    {
        Rival: 'Competitor08',
        Color: '#5e35b1',
    },
    {
        Rival: 'Competitor09',
        Color: '#ff7043',
    },
    {
        Rival: 'Competitor10',
        Color: '#f6ff00',
    },
    {
        Rival: 'Competitor11',
        Color: '#6c6c6c',
    },
    {
        Rival: 'Competitor12',
        Color: '#59bf00',
    },
    {
        Rival: 'Competitor13',
        Color: '#9e67ab',
    },
    {
        Rival: 'Competitor14',
        Color: '#03a9f4',
    },
    {
        Rival: 'Competitor15',
        Color: '#ec6d6d',
    },
    {
        Rival: 'Competitor16',
        Color: '#f66d00',
    },
    {
        Rival: 'Competitor17',
        Color: '#b199e1',
    },
    {
        Rival: 'Competitor18',
        Color: '#f15a60',
    },
    {
        Rival: 'Competitor19',
        Color: '#7d7c7c',
    },
    {
        Rival: 'Competitor20',
        Color: '#ffa0fc',
    },
    {
        Rival: 'Competitor21',
        Color: '#2e7d32',
    },
    {
        Rival: 'Competitor22',
        Color: '#f48fb1',
    },
    {
        Rival: 'Competitor23',
        Color: '#ff00dd',
    },
    {
        Rival: 'Competitor24',
        Color: '#c58af9',
    },
    {
        Rival: 'Competitor25',
        Color: '#ec407a',
    },
    {
        Rival: 'Competitor26',
        Color: '#bfeaa4',
    },
    {
        Rival: 'Competitor27',
        Color: '#af7c1a',
    },
    {
        Rival: 'Competitor28',
        Color: '#c11000',
    },
    {
        Rival: 'Competitor29',
        Color: '#57A89F',
    },
    {
        Rival: 'Competitor30',
        Color: '#8aff90',
    },
    {
        Rival: 'Competitor31',
        Color: '#2c9d13',
    },
    {
        Rival: 'Competitor32',
        Color: '#737373',
    },
    {
        Rival: 'Competitor33',
        Color: '#f75600',
    },
    {
        Rival: 'Competitor34',
        Color: '#bfeb92',
    },
    {
        Rival: 'Competitor35',
        Color: '#5a9bd4',
    },
    {
        Rival: 'Competitor36',
        Color: '#7cb342',
    },
    {
        Rival: 'Competitor37',
        Color: '#1525ff',
    },
    {
        Rival: 'Competitor38',
        Color: '#ff8989',
    },
    {
        Rival: 'Competitor39',
        Color: '#eeac6e',
    },
    {
        Rival: 'Competitor40',
        Color: '#aeae04',
    },
];