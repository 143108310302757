import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../context/ContextProvider'
import { useLocation } from 'react-router-dom'
import { FetchRenderData } from '../services/FetchRenderData'
import { customSort } from '../utils/customSort'
import { parseData } from '../utils/parseData'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { Dummy } from '../components/select/Dummy'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Loading } from '../components/Loader/Loading'
import { LineCharts } from '../components/visualize/LineCharts'
import axios from 'axios'

const dataCalculation = (data, series_field) => {
    let tempDict = {}

    for (let ele of data) {
        if (!(ele.local_scraped_at_timestamp in tempDict)) {
            tempDict[ele.local_scraped_at_timestamp] = {
                [ele[series_field]]: {
                    "total": ele.record_count,
                    "null_count": ele.sorry_message === null ? ele.record_count : 0
                }
            }
        } else {
            if (!(ele[series_field] in tempDict[ele.local_scraped_at_timestamp])) {
                tempDict[ele.local_scraped_at_timestamp][ele[series_field]] = {
                    "total": ele.record_count,
                    "null_count": ele.sorry_message === null ? ele.record_count : 0
                }
            } else {
                if ("total" in tempDict[ele.local_scraped_at_timestamp][ele[series_field]]) {
                    tempDict[ele.local_scraped_at_timestamp][ele[series_field]]["total"] += ele.record_count
                    tempDict[ele.local_scraped_at_timestamp][ele[series_field]]["null_count"] += ele.sorry_message === null ? ele.record_count : 0
                }
            }
        }
    }

    let resultant_data = []

    for (let timeFrame in tempDict) {
        for (let source in tempDict[timeFrame]) {
            resultant_data.push({
                percent: ((tempDict[timeFrame][source]["null_count"] / tempDict[timeFrame][source]["total"]) * 100).toFixed(2),
                [series_field]: source,
                local_scraped_at_timestamp: timeFrame
            })
        }
    }

    return resultant_data;

}

export const AvailabilityTab = () => {

    const {
        desire_position,
        isExtended,
        createDimensionsFilters,
        getHashingId, firstReload,
        handleMultiselect,
        getAccessTokenSilently,
        filters, selectedFilters, setSelectedFilters,
        firstReloadAT, setFirstReloadAT,
        availabilityTabSourceData, setAvailabilityTabSourceData,
        availabilityTabSourceVehicleCategoryData, setAvailabilityTabSourceVehicleCategoryData
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname


    const getChartData = async (selectedFilters, useDataHook, setDataHook, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, useDataHook.data.fields, getAccessTokenSilently, false, 0, 0, axiosToken, firstReload)

            if (res_json === null || res_json === undefined) return

            let parse_data = dataCalculation(parseData(res_json), useDataHook.data.dimensions.series_field)

            let sortedData = customSort(customSort(parse_data, "local_scraped_at_timestamp", "ASC"), useDataHook.data.dimensions.series_field, "ASC")

            setDataHook((prev) => ({ ...prev, id: hashId, data: { ...prev.data, chart_data: sortedData } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }

    useEffect(() => {
        if (selectedFilters.id !== availabilityTabSourceData.id) {

            if (timeoutId.current) { timeoutId.current.cancel("Previous request canceled") };
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setSpinning(() => true)

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getChartData(selectedFilters, availabilityTabSourceData, setAvailabilityTabSourceData, axiosToken),
                        getChartData(selectedFilters, availabilityTabSourceVehicleCategoryData, setAvailabilityTabSourceVehicleCategoryData, axiosToken)
                    ])

                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadAT(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return selectedFilters ? <DateRange key={ index } selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} /> : <Dummy key={ index } value={ key } />
                        } else {
                            let option = filters.find(option => option.key.value === key)
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <div className="chart_container">
                        <LineCharts data={ availabilityTabSourceData.data } firstRender={ firstReloadAT } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ availabilityTabSourceVehicleCategoryData.data } firstRender={ firstReloadAT } />
                    </div>
                </div>
            </div>
        </div>
    )
}
