import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../context/ContextProvider'
import { SingleSelect } from '../components/select/SingleSelect'

import { Dummy } from '../components/select/Dummy'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { Loading } from '../components/Loader/Loading'
import { MultiSelect } from '../components/select/MultiSelect'
import { Heading } from '../components/label/Heading'
import { ComparisonTable } from '../components/visualize/ComparisonTable'
import { FetchRenderData } from '../services/FetchRenderData'
import axios from 'axios'
import { AvailabilityChart } from '../components/visualize/AvailabilityChart'
import { useLocation } from 'react-router-dom'

const hiddenFilters = ["day_category", "timeframe", "time_interval", "source_vehicle_category"]

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


const twoDigitString = (num) => {
    return num < 10 ? '0' + num : '' + num;
}

const arrayAverage = (arr) => {
    const filteredArray = arr.filter(ele => (ele !== null && ele !== undefined));

    if (filteredArray.length === 0) return null;

    const sum = filteredArray.reduce((acc, num) => acc + num, 0);
    return sum / filteredArray.length;
}

// Function to convert date-hour format
const convertToDayHour = (dateTime) => {
    const [date, hour] = dateTime.split(' ');
    const dayIndex = new Date(date).getDay();
    const dayOfWeek = daysOfWeek[dayIndex];
    return `${dayOfWeek} ${hour}`;
}

const formatData = (selectedVehicleCategory, data) => {
    let left = []
    let right = []

    let left_key = selectedVehicleCategory.left.options[0].value
    let right_key = selectedVehicleCategory.right.options[0].value

    const timestamps = data.find(item => item.key === 'local_scraped_at_timestamp').values;
    const vehicle_eta_list = data.find(item => item.key === 'vehicle_eta').values;
    const upfront_fare_list = data.find(item => item.key === 'upfront_fare').values;
    const source_vehicle_category_list = data.find(item => item.key === 'source_vehicle_category').values;

    for (let i = 0; i < source_vehicle_category_list.length; i++) {
        let temp = {
            vehicle_eta: vehicle_eta_list[i],
            upfront_fare: upfront_fare_list[i],
            source_vehicle_category: source_vehicle_category_list[i],
            local_scraped_at_timestamp: convertToDayHour(timestamps[i])
        }

        if (left_key === right_key) {
            left.push(temp)
            right.push(temp)
        } else if (source_vehicle_category_list[i] === left_key) {
            left.push(temp)
        } else {
            right.push(temp)
        }
    }

    return { left, right }
}


const createDataset = (data) => {
    const dataset = Object.fromEntries([...Array(24).keys()].map(i => [
        twoDigitString(i), Object.fromEntries(daysOfWeek.map(day => [day, { eta: { value: [] }, price: { value: [] }, hour: twoDigitString(i) }]))
    ]));

    data.forEach((obj) => {
        let [day, hour] = obj.local_scraped_at_timestamp.split(" ")
        dataset[hour][day].eta.value.push(obj.vehicle_eta)
        dataset[hour][day].price.value.push(obj.upfront_fare)
    })

    for (let hour in dataset) {
        for (let day in dataset[hour]) {

            dataset[hour][day].eta.value = arrayAverage(dataset[hour][day].eta.value)
            dataset[hour][day].price.value = arrayAverage(dataset[hour][day].price.value)
        }
    }

    return dataset
}


// Function to calculate percentage difference
const calculatePercentageDifference = (value1, value2) => {
    if (value1 === null || value2 === null || value1 === undefined || value2 === undefined) return 0;
    return ((value1.toFixed(1) - value2.toFixed(1)) / value2.toFixed(1)) * 100;
}

// Function to assign color based on percentage difference
const assignColor = (percentageDifference) => {
    if (percentageDifference > 20) {
        return '#d30f00';
    } else if (percentageDifference > 10) {
        return '#f06a62';
    } else if (percentageDifference > 0) {
        return '#f5a19c';
    } else if (percentageDifference === 0) {
        return "#ffffff";
    } else if (percentageDifference >= -10 && percentageDifference < 0) {
        return '#bbe6ba';
    } else if (percentageDifference >= -20 && percentageDifference < -10) {
        return '#76cd73';
    } else {
        return '#3da53b';
    }
}



// Function to add color field to each data point based on comparison
const addColorFieldBasedOnComparison = (left_dataset, right_dataset) => {

    let left_dataset_list = []
    let right_dataset_list = []

    for (let i = 0; i < 24; i++) {
        let hour = twoDigitString(i)

        for (let day of daysOfWeek) {
            left_dataset[hour][day]["eta"]["color"] = assignColor(calculatePercentageDifference(left_dataset[hour][day].eta.value, right_dataset[hour][day].eta.value))
            right_dataset[hour][day]["eta"]["color"] = assignColor(calculatePercentageDifference(right_dataset[hour][day].eta.value, left_dataset[hour][day].eta.value))

            left_dataset[hour][day]["price"]["color"] = assignColor(calculatePercentageDifference(left_dataset[hour][day].price.value, right_dataset[hour][day].price.value))
            right_dataset[hour][day]["price"]["color"] = assignColor(calculatePercentageDifference(right_dataset[hour][day].price.value, left_dataset[hour][day].price.value))
        }
        left_dataset[hour]["hour"] = hour
        right_dataset[hour]["hour"] = hour

        left_dataset_list.push(left_dataset[hour])
        right_dataset_list.push(right_dataset[hour])

    }

    return { leftProcessedData: left_dataset_list, rightProcessedData: right_dataset_list }
}


export const HeatMapTab = () => {
    const {
        desire_position,
        isExtended,
        handleMultiselect,
        getAccessTokenSilently,
        createDimensionsFilters,
        getHashingId, firstReload,
        firstReloadHM, setFirstReloadHM,
        filters,
        selectedFilters, setSelectedFilters,
        getSourceStatus,
        sourceStatusHM, setSourceStatusHM,
        selectedVehicleCategory, setSelectedVehicleCategory,
        heatMapData, setHeatMapData
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname
    const [vehicleCategoryOption, setVehicleCategoryOption] = useState(null)
    const [focusCell, setFocusCell] = useState({ hour: null, day: null })


    const handleCategorySelect = async ({ baseURI, filter_key, filter_cur_payload }) => {
        if (filter_key === "source_vehicle_category_left") {
            setSelectedVehicleCategory((prev) => ({ ...prev, left: filter_cur_payload }))
        } else if (filter_key === "source_vehicle_category_right") {
            setSelectedVehicleCategory((prev) => ({ ...prev, right: filter_cur_payload }))
        }
    };

    const handleCellClick = ({ hour, day }) => {
        if (focusCell.hour !== hour || focusCell.day !== day) {
            setFocusCell({ hour: hour, day: day })
        } else {
            setFocusCell({ hour: null, day: null })
        }
    }


    const getData = async (hashId, axiosToken) => {
        try {
            if (selectedVehicleCategory.left === null || selectedVehicleCategory.right === null) return
            if (selectedVehicleCategory.left?.options.length === 0 || selectedVehicleCategory.right?.options.length === 0) return

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })

            let addDimensionsFilters = [...dimensionsFilters, { "fieldName": "source_vehicle_category", "values": [selectedVehicleCategory.left.options[0].value, selectedVehicleCategory.right.options[0].value], "negation": false }]

            let res_json = await FetchRenderData(addDimensionsFilters, selectedFilters.date_range, heatMapData.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            if (res_json === null || res_json === undefined) return

            let { left, right } = formatData(selectedVehicleCategory, res_json)

            const left_dataset = createDataset(left)
            const right_dataset = createDataset(right)

            const { leftProcessedData, rightProcessedData } = addColorFieldBasedOnComparison(left_dataset, right_dataset)

            setHeatMapData((prev) => ({ ...prev, id: hashId, data: { ...prev.data, left: leftProcessedData, right: rightProcessedData } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }

    useEffect(() => {
        let vehicle_category_option = filters.find(option => option.key.value === "source_vehicle_category");

        if (vehicle_category_option && vehicle_category_option.default.options.length > 0) {

            vehicle_category_option = JSON.parse(JSON.stringify(vehicle_category_option))
            
            vehicle_category_option.default.options = [vehicle_category_option.options[0]]
            vehicle_category_option.default.negation = false

            let leftExistingSelectedValue = selectedVehicleCategory.left === null ? false : vehicle_category_option.options.some((option) => option.value === selectedVehicleCategory.left.options[0].value)

            let rightExistingSelectedValue = selectedVehicleCategory.right === null ? false : vehicle_category_option.options.some((option) => option.value === selectedVehicleCategory.right.options[0].value)

            setVehicleCategoryOption({
                left: {
                    key: { label: "Category 1", value: "source_vehicle_category_left" },
                    options: vehicle_category_option.options,
                    default: leftExistingSelectedValue ? { options: [selectedVehicleCategory.left.options[0]], negation: false } : vehicle_category_option.default
                },
                right: {
                    key: { label: "Category 2", value: "source_vehicle_category_right" },
                    options: vehicle_category_option.options,
                    default: rightExistingSelectedValue ? { options: [selectedVehicleCategory.right.options[0]], negation: false } : vehicle_category_option.default
                }
            })

            setSelectedVehicleCategory((prev) => ({
                ...prev,
                left: leftExistingSelectedValue ? { options: [selectedVehicleCategory.left.options[0]], negation: false } : vehicle_category_option.default,
                right: rightExistingSelectedValue ? { options: [selectedVehicleCategory.right.options[0]], negation: false } : vehicle_category_option.default
            }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {

        const handleUseEffect = async () => {

            let source_vehicle_category = {
                options: [
                    { label: selectedVehicleCategory.left.options[0].label, value: selectedVehicleCategory.left.options[0].value },
                    { label: selectedVehicleCategory.right.options[0].label, value: selectedVehicleCategory.right.options[0].value },
                ], negation: false
            }

            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters, addOn: source_vehicle_category })

            if (hashId !== heatMapData.id) {

                if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
                let axiosToken = axios.CancelToken.source()
                timeoutId.current = axiosToken

                setTimeout(async () => {
                    try {
                        let results = await Promise.all([
                            getSourceStatus({ currentTab: current_tab, sourceStatusHook: sourceStatusHM, setSourceStatusHook: setSourceStatusHM, axiosToken: axiosToken, isFirstReload: firstReload, selectedFiltersHook: selectedFilters }),
                            getData(hashId, axiosToken)
                        ])
                        if (!(results.includes(false))) setSpinning(() => false)
                        setFirstReloadHM(false)
                    } catch (error) {
                        console.error(error)
                        throw new Error("Something Went Wrong")
                    }
                })
            } else {
                setSpinning(() => false)
            }
        }

        setSpinning(() => true)

        if (selectedVehicleCategory.left === null || selectedVehicleCategory.right === null) return
        if (selectedVehicleCategory.left?.options.length === 0 || selectedVehicleCategory.right?.options.length === 0) return

        handleUseEffect()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, selectedVehicleCategory])


    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container" style={ { gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))" } }>
                    { desire_position.map((key, index) => {
                        if (hiddenFilters.includes(key)) {
                            return null;
                        } else if (key === null) {
                            return selectedFilters ? <DateRange key={ index } selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} /> : <Dummy key={ index } value={ key } />
                        } else {
                            let option = filters.find(option => option.key.value === key);
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === "country_code_currency" || key === "city") {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <div className="chart_container2">
                        <AvailabilityChart data={ sourceStatusHM.data } firstRender={ firstReloadHM } />
                    </div>
                    <div>
                        <Heading label={ heatMapData.data.label } fields={ heatMapData.data.fields } />
                        <div style={ { display: "flex", margin: "10px auto", width: "100%", justifyContent: "space-around" } }>
                            <div style={ { width: "280px", margin: "auto 0" } } >{ vehicleCategoryOption ? <SingleSelect key={ "vehicle_category_option_left" } data={ vehicleCategoryOption.left } handleFunc={ handleCategorySelect } reRender={ vehicleCategoryOption } /> : <Dummy key={ "vehicle_category_option_left" } /> }</div>
                            <div style={ { minWidth: "280px", margin: "auto 0" } } >{ vehicleCategoryOption ? <SingleSelect key={ "vehicle_category_option_right" } data={ vehicleCategoryOption.right } handleFunc={ handleCategorySelect } reRender={ vehicleCategoryOption } /> : <Dummy key={ "vehicle_category_option_right" } /> }</div>
                        </div>
                        <div style={ { width: "100%", margin: "10px auto 10px" } } > <Heading label={ "Search ETA in min" } /></div>
                        <div className='customLegendTable'>
                            <li><span><span style={ { backgroundColor: "#3da53b", border: "1px solid black" } }></span><p>{ "< -20%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#76cd73", border: "1px solid black" } }></span><p>{ "-20% to -10%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#bbe6ba", border: "1px solid black" } }></span><p>{ "-10% to 0%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#ffffff", border: "1px solid black" } }></span><p>{ "0%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#f5a19c", border: "1px solid black" } }></span><p>{ "0% to +10%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#f06a62", border: "1px solid black" } }></span><p>{ "+10% to +20%" }</p> </span></li>
                            <li><span><span style={ { backgroundColor: "#d30f00", border: "1px solid black" } }></span><p>{ "> +20%" }</p></span></li>
                        </div>

                        <div style={ { display: "flex", width: "100%", justifyContent: "space-between" } }>
                            <div style={ { width: "48%", height: "fit-content" } } >
                                <ComparisonTable data={ heatMapData.data.left } data_key={ "eta" } firstRender={ firstReloadHM } handleCellClick={ handleCellClick } focusCell={ focusCell } />
                            </div>
                            <div style={ { width: "48%", height: "fit-content" } } >
                                <ComparisonTable data={ heatMapData.data.right } data_key={ "eta" } firstRender={ firstReloadHM } handleCellClick={ handleCellClick } focusCell={ focusCell } />
                            </div>
                        </div>

                        <div style={ { width: "100%", margin: "10px auto 10px" } } > <Heading label={ "Search Price" } /> </div>
                        <div className='customLegendTable'>
                            <li><span><span style={ { backgroundColor: "#3da53b", border: "1px solid black" } }></span><p>{ "< -20%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#76cd73", border: "1px solid black" } }></span><p>{ "-20% to -10%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#bbe6ba", border: "1px solid black" } }></span><p>{ "-10% to 0%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#ffffff", border: "1px solid black" } }></span><p>{ "0%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#f5a19c", border: "1px solid black" } }></span><p>{ "0% to +10%" }</p></span></li>
                            <li><span><span style={ { backgroundColor: "#f06a62", border: "1px solid black" } }></span><p>{ "+10% to +20%" }</p> </span></li>
                            <li><span><span style={ { backgroundColor: "#d30f00", border: "1px solid black" } }></span><p>{ "> +20%" }</p></span></li>
                        </div>
                        <div style={ { display: "flex", width: "100%", justifyContent: "space-between" } }>
                            <div style={ { width: "48%", height: "fit-content" } } >
                                <ComparisonTable data={ heatMapData.data.left } data_key={ "price" } firstRender={ firstReloadHM } handleCellClick={ handleCellClick } focusCell={ focusCell } />
                            </div>
                            <div style={ { width: "48%", height: "fit-content" } } >
                                <ComparisonTable data={ heatMapData.data.right } data_key={ "price" } firstRender={ firstReloadHM } handleCellClick={ handleCellClick } focusCell={ focusCell } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
