import React, { useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./DateRange.css"

import { hashing } from '../../utils/hashing';


export const DateRange = ({selectedFilterHook, setSelectedFilterHook}) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();
    const [isStartSunday, setIsStartSunday] = useState(false)
    const [selectedShortCut, setSelectedShortCut] = useState("None")
    const [tempDateRange, setTempDateRange] = useState({ startDate: null, endDate: null })


    const handleDateChange = ({ key, date }) => {
        setTempDateRange((prev) => ({ ...prev, [key]: formatDateNum(date) }))
    }


    const handleSelectChange = (value) => {
        const formattedDate = getDateRange(value);
        setSelectedShortCut(value)
        setTempDateRange(formattedDate)
    };


    const applyDateRangeChange = async () => {
        let hash = await hashing(selectedFilterHook.data, tempDateRange)
        setSelectedFilterHook((prev) => ({ ...prev, id: hash, date_range: tempDateRange }))

        setIsOpen(false)
    }


    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };


    const formatDateNum = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const formatDateStr = (inputDateString) => {
        const inputDate = new Date(inputDateString);
        return inputDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    }


    const reverseMapOption = (startDate, endDate) => {
        // Iterate over each option and check if the given date range matches
        const options = ['None', 'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month'];

        for (const option of options) {
            const { startDate: optionStartDate, endDate: optionEndDate } = getDateRange(option);
            if (startDate === optionStartDate && endDate === optionEndDate) {
                return option;
            }
        }

        return null;
    };


    const getDateRange = (option) => {
        const currentDate = new Date();
        let startDate = new Date();
        let endDate = new Date();

        switch (option) {
            case 'Today':
                startDate = currentDate;
                endDate = currentDate;
                break;
            case 'Yesterday':
                startDate.setDate(currentDate.getDate() - 1);
                endDate.setDate(currentDate.getDate() - 1);
                break;
            case 'This Week':
                if (isStartSunday) {
                    startDate.setDate(currentDate.getDate() - currentDate.getDay());
                } else {
                    startDate.setDate(currentDate.getDate() - (currentDate.getDay() + 6) % 7);
                }
                endDate = currentDate;
                break;
            case 'Last Week':
                if (isStartSunday) {
                    startDate.setDate(currentDate.getDate() - currentDate.getDay() - 7);
                    endDate.setDate(startDate.getDate() + 6);
                } else {
                    startDate.setDate(currentDate.getDate() - (currentDate.getDay() + 6) % 7 - 7); // Adjust to Monday of last week
                    endDate.setDate(startDate.getDate() + 6);
                }
                break;
            case 'This Month':
                startDate.setDate(1);
                endDate = currentDate;
                break;
            case 'Last Month':
                startDate.setMonth(currentDate.getMonth() - 1);
                startDate.setDate(1);
                endDate = new Date(startDate);
                endDate.setMonth(startDate.getMonth() + 1);
                endDate.setDate(0);
                break;
            default:
                startDate.setDate(currentDate.getDate() - 1);
        }

        return {
            startDate: formatDateNum(startDate),
            endDate: formatDateNum(endDate),
        };
    };


    useEffect(() => {
        if (selectedShortCut === "This Week" || selectedShortCut === "Last Week") {
            const currentDate = new Date();
            let startDate = new Date();
            let endDate = new Date();

            if (selectedShortCut === "This Week") {
                if (isStartSunday) {
                    startDate.setDate(currentDate.getDate() - currentDate.getDay());
                } else {
                    startDate.setDate(currentDate.getDate() - (currentDate.getDay() + 6) % 7);
                }
                endDate = currentDate;
            } else if (selectedShortCut === "Last Week") {
                if (isStartSunday) {
                    startDate.setDate(currentDate.getDate() - currentDate.getDay() - 7);
                    endDate.setDate(startDate.getDate() + 6);
                } else {
                    startDate.setDate(currentDate.getDate() - (currentDate.getDay() + 6) % 7 - 7); // Adjust to Monday of last week
                    endDate.setDate(startDate.getDate() + 6);
                }
            }

            setTempDateRange({ startDate: formatDateNum(startDate), endDate: formatDateNum(endDate) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStartSunday])


    useEffect(() => {
        const lastSelectedShortCut = reverseMapOption(selectedFilterHook.date_range.startDate, selectedFilterHook.date_range.endDate)
        setSelectedShortCut(lastSelectedShortCut ? lastSelectedShortCut : "None")
        setTempDateRange(selectedFilterHook.date_range)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='date_range_container' ref={ dropdownRef }>
            <button onClick={ () => setIsOpen(!isOpen) }>
                <span>{ formatDateStr(selectedFilterHook.date_range.startDate) } - { formatDateStr(selectedFilterHook.date_range.endDate) }</span>
                <span style={ { fontSize: "8px", fontWeight: "100" } }>▼</span>
            </button>
            { isOpen && <div className='date_picker_box' >
                <div className='header'>
                    <div style={ { display: "flex", gap: "5px", alignItems: "center" } }>
                        { (selectedShortCut === "This Week" || selectedShortCut === "Last Week") && <> <input type='checkbox' checked={ isStartSunday } onChange={ () => setIsStartSunday(!isStartSunday) } />
                            <label style={ { margin: "auto", fontWeight: "500" } } >Start Sunday</label> </> }
                    </div>
                    <div className='shortcuts'>
                        <select defaultValue={ selectedShortCut } onChange={ (e) => handleSelectChange(e.target.value) }>
                            <option value={ "None" }>Fixed</option>
                            <option value={ "Today" }>Today</option>
                            <option value={ "Yesterday" }>Yesterday</option>
                            <option value={ "This Week" }>This Week</option>
                            <option value={ "Last Week" }>Last Week</option>
                            <option value={ "This Month" }>This Month</option>
                            <option value={ "Last Month" }>Last Month</option>
                        </select>
                    </div>
                </div>
                <div style={ { display: "flex", gap: "5px", width: "fit-content" } }>
                    <div name="startDate" >
                        <p>Start Date</p>
                        <Calendar
                            nextLabel={ <img className='next_prev_label' src='right_icon.png' alt='right arrow' /> }
                            prevLabel={ <img className='next_prev_label' src='left_icon.png' alt='left arrow' /> }
                            next2Label={ <img className='next_prev_label' src='double_right_icon.png' alt='left arrow' /> }
                            prev2Label={ <img className='next_prev_label' src='double_left_icon.png' alt='left arrow' /> }
                            onChange={ (date) => handleDateChange({ key: "startDate", date }) }
                            calendarType={ "iso8601" } value={ tempDateRange.startDate }
                            showFixedNumberOfWeeks={ true }
                        />
                    </div>
                    <div name="endDate">
                        <p>End Date</p>
                        <Calendar
                            nextLabel={ <img className='next_prev_label' src='right_icon.png' alt='right arrow' /> }
                            prevLabel={ <img className='next_prev_label' src='left_icon.png' alt='left arrow' /> }
                            next2Label={ <img className='next_prev_label' src='double_right_icon.png' alt='left arrow' /> }
                            prev2Label={ <img className='next_prev_label' src='double_left_icon.png' alt='left arrow' /> }
                            onChange={ (date) => handleDateChange({ key: "endDate", date }) }
                            calendarType={ "iso8601" } value={ tempDateRange.endDate }
                            showFixedNumberOfWeeks={ true }
                        />
                    </div>
                </div>
                <div className='footer'>
                    <button onClick={ () => setIsOpen(!isOpen) }>Cancel</button>
                    <button onClick={ () => applyDateRangeChange() }>Apply</button>
                </div>
            </div> }
        </div>

    );
}
