
import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/Page.css'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { FetchRenderData } from '../services/FetchRenderData'
import { Loading } from '../components/Loader/Loading'
import { LineCharts } from '../components/visualize/LineCharts'
import { customSort } from '../utils/customSort'
import { parseData } from '../utils/parseData'
import axios from 'axios'
import { AvailabilityChart } from '../components/visualize/AvailabilityChart'
import { useLocation } from 'react-router-dom'


export const PriceComponentTab = () => {
    const {
        desire_position,
        isExtended,
        createDimensionsFilters,
        getHashingId, firstReload,
        firstReloadPC, setFirstReloadPC,
        selectedFilters, setSelectedFilters,
        filters,
        handleMultiselect,
        getAccessTokenSilently,
        getSourceStatus,
        allRecordCountVsTime, setAllRecordCountVsTime,
        priceTimeSource, setPriceTimeSource,
        surgeTimeSource, setSurgeTimeSource,
        pricePerKMTimeSource, setPricePerKMTimeSource,
        pricePerMinTimeSource, setPricePerMinTimeSource,
        baseFareTimeSource, setBaseFareTimeSource,
        cancellationFeeTimeSource, setCancellationFeeTimeSource,
        minimumFeeTimeSource, setMinimumFeeTimeSource,
        waitingFeeTimeSource, setWaitingFeeTimeSource,
        highDemandFeeTimeSource, setHighDemandFeeTimeSource
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname

    const getChartData = async (selectedFilters, useDataHook, setDataHook, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, useDataHook.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)

            if (res_json === null || res_json === undefined) return

            const sortedData = customSort(customSort(parseData(res_json), "local_scraped_at_timestamp", "ASC"), "source", "ASC")

            setDataHook((prev) => ({ ...prev, id: hashId, data: { ...prev.data, chart_data: sortedData } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    useEffect(() => {
        if (selectedFilters.id !== priceTimeSource.id) {
            setSpinning(() => true)

            if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getSourceStatus({ currentTab: current_tab, sourceStatusHook: allRecordCountVsTime, setSourceStatusHook: setAllRecordCountVsTime, axiosToken: axiosToken, isFirstReload: firstReload, selectedFiltersHook: selectedFilters }),
                        getChartData(selectedFilters, priceTimeSource, setPriceTimeSource, axiosToken),
                        getChartData(selectedFilters, surgeTimeSource, setSurgeTimeSource, axiosToken),
                        getChartData(selectedFilters, pricePerKMTimeSource, setPricePerKMTimeSource, axiosToken),
                        getChartData(selectedFilters, pricePerMinTimeSource, setPricePerMinTimeSource, axiosToken),
                        getChartData(selectedFilters, baseFareTimeSource, setBaseFareTimeSource, axiosToken),
                        getChartData(selectedFilters, cancellationFeeTimeSource, setCancellationFeeTimeSource, axiosToken),
                        getChartData(selectedFilters, minimumFeeTimeSource, setMinimumFeeTimeSource, axiosToken),
                        getChartData(selectedFilters, waitingFeeTimeSource, setWaitingFeeTimeSource, axiosToken),
                        getChartData(selectedFilters, highDemandFeeTimeSource, setHighDemandFeeTimeSource, axiosToken),
                    ])

                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadPC(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return <DateRange key={ index } selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} />
                        } else {
                            let option = filters.find(option => option.key.value === key);
                            if (option === undefined) {
                                return selectedFilters ? <DateRange key={ index } selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} /> : <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>
                <div className="visualize">
                    <Loading spinning={ spinning } />
                    <div className="chart_container2">
                        <AvailabilityChart data={ allRecordCountVsTime.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ priceTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ surgeTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ pricePerKMTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ pricePerMinTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ baseFareTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ cancellationFeeTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ minimumFeeTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ waitingFeeTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ highDemandFeeTimeSource.data } firstRender={ firstReloadPC } />
                    </div>
                </div>
            </div>
        </div>
    )
}