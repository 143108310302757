import React from 'react'
import { Scatter } from '@ant-design/plots';
import { Heading } from '../label/Heading';
import { colorData } from '../../utils/colors.js'
import { NoDataError } from './NoDataError.js';

const formatValue = (value) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? value : parsedValue.toFixed(2);
};

export const ScatterCharts = ({ data, firstRender }) => {
  
  let legends = [...new Set(data.chart_data.filter((ele)=> ele[data.dimensions.y.field] !== null).map((ele) => ele[data.dimensions.series_field]))].filter(legend => legend !== null);
  
  const config = {
    data: data.chart_data.filter((ele)=> ele[data.dimensions.y.field] !== null),
    colorField: data.dimensions.series_field,
    yField: data.dimensions.y.field,
    xField: data.dimensions.x.field,
    size: 5,
    shape: 'circle',
    appendPadding: 10,
    pointStyle: { fillOpacity: 1 },
    yAxis: { title: { text: data.dimensions.y.title.text }, line: null, label: { formatter: (value) => `${formatValue(value)}` } },
    xAxis: { title: { text: data.dimensions.x.title.text }, line: null, label: { formatter: (value) => `${formatValue(value)}` } },
    label: { formatter: (obj) => "" },
    legend: { position: 'top-left' },
    color: legends.map((legend) => {
      return colorData.find((ele) => legend.toLowerCase().includes(ele.Rival.toLowerCase())) ? colorData.find((ele) => legend.toLowerCase().includes(ele.Rival.toLowerCase()))["Color"] : "#ff7b00"}),
    tooltip: {
      customContent: (title, items) => {
        return `<table style="margin: 10px 5px;">
          ${items.map((item) => `
            <tr style="border: 1px solid blue; padding: 10px 0px; text-align: left; font-weight: 900;"> 
              <td style="padding: 5px 0px;" >${item.name === data.dimensions.y.field ? data.dimensions.y.title.text : item.name === data.dimensions.x.field ? data.dimensions.x.title.text : item.name === "source" ? "Rival" : "Vehicle Category"}</td>
              <td style="padding-left: 3px;">:</td>
              <td style="padding-left: 5px;">${item.value} </td>
            </tr>`
        ).join("\n")}
        </table>`;
      },
    },
  };


  return (
    <>
      <Heading label={ data.label } fields={ data.fields } />
      { data.chart_data.length > 0 ? <Scatter style={ { height: "90%" } } { ...config } /> : <NoDataError firstRender={firstRender} /> }
    </>
  )
}
