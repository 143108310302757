import React from 'react';
import { Table } from 'antd';
import "./styles/Charts.css"
import { NoDataError } from './NoDataError';



export const ComparisonTable = ({ data, data_key, firstRender, handleCellClick, focusCell }) => {

    const columns = [
        {
            title: 'Hour',
            dataIndex: 'hour',
            key: 'hour',
            render: (record) => (
                <span style={ { padding: "1px", background: "white", width: "100%", height: "20px", fontWeight: "900", color: "black"} }>
                    { record }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Monday',
            dataIndex: 'Monday',
            key: 'Monday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Monday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Monday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Tuesday',
            dataIndex: 'Tuesday',
            key: 'Tuesday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Tuesday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Tuesday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Wednesday',
            dataIndex: 'Wednesday',
            key: 'Wednesday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Wednesday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Wednesday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Thursday',
            dataIndex: 'Thursday',
            key: 'Thursday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Thursday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Thursday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Friday',
            dataIndex: 'Friday',
            key: 'Friday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Friday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Friday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Saturday',
            dataIndex: 'Saturday',
            key: 'Saturday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Saturday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Saturday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        },
        {
            title: 'Sunday',
            dataIndex: 'Sunday',
            key: 'Sunday',
            render: (record) => (
                <span style={ {
                    padding: "1px",
                    background: record[data_key].color,
                    color: "#000",
                    width: "100%",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: focusCell.hour === record.hour && focusCell.day === "Sunday" ? "2px solid black" : "none",
                    cursor: "pointer"
                } } onClick={()=> handleCellClick({hour: record.hour, day: "Sunday"})}>
                    { record[data_key].value === null ? null : record[data_key].value.toFixed(1) }
                </span>
            ),
            width: "12%"
        }
    ];


    return (
        <>{ data.length > 0 ? <Table
            className="my-data-table"
            columns={ columns }
            dataSource={ data }
            virtual={ true }
            size="small"
            pagination={ false }
            scroll={ { y: 520 } }
            rowKey="hour"
        /> : <NoDataError firstRender={ firstRender } /> }</>

    );
};
