import { sha256 } from 'crypto-hash';

const sortObject = (obj) => {
    if (typeof obj !== 'object' || obj === null) { return obj }

    if (Array.isArray(obj)) { return obj.map(sortObject) }

    const sortedObj = {};

    Object.keys(obj)
        .sort()
        .forEach((key) => {
            if (Array.isArray(obj[key])) {
                // Sort the options array based on the "value" property
                sortedObj[key] = obj[key].sort((a, b) => (a.value > b.value ? 1 : -1));
            } else {
                sortedObj[key] = sortObject(obj[key]);
            }
        });

    return sortedObj;
}


export const hashing = async (obj, date_range) => {
    // Ensure obj is not null or undefined
    if (!obj || !date_range) { return null }

    const sortedObject = sortObject({ data: obj, date_range: date_range })

    // Hash the sorted object
    const hash = await sha256(JSON.stringify(sortedObject));
    return hash;
};
