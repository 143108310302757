import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/plots';
import { NoDataError } from './NoDataError';
import { Heading } from '../label/Heading';

export const BarCharts = ({ data, firstRender }) => {
    const [chartHeight, setChartHeight] = useState(0);

    useEffect(() => {
        console.log("Data changed. Recalculating height...");
        // Calculate the height based on the number of bars
        const minHeight = 150;
        const estimatedHeightPerBar = 35; 
        const numberOfBars = data.chart_data.length;
        const calculatedHeight = Math.max(minHeight, numberOfBars * estimatedHeightPerBar);

        setChartHeight(calculatedHeight);
    }, [data.chart_data]);

    
    const sortData = (initial_data) => {
        let cityUsages = {}

        initial_data.forEach(element => {
            if (element.city in cityUsages) {
                cityUsages[element.city] += element.count === null ? 0 : element.count
            } else {
                cityUsages[element.city] = element.count === null ? 0 : element.count
            }
        });

        let final_data = [];

        for (let city in cityUsages) {
            final_data.push({city: city, count: cityUsages[city]})
        }

        final_data = final_data.sort((a, b) => b.count - a.count);
        return final_data;
    }

    const config = {
        data: sortData(data.chart_data),
        isStack: true,
        xField: data.dimensions.x.field,
        yField: data.dimensions.y.field,
        seriesField: data.dimensions.series_field,
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
        },
        legend: {
            layout: 'horizontal',
            position: 'top-left',
            itemName: { style: { fill: '#333' } },
            spacing: 10
        },
        height: chartHeight // Set the height dynamically
    };

    return (
        <div style={ { height: chartHeight, marginBottom: "40px" } } className="chart_container">
            <Heading label={ data.label } data={sortData(data.chart_data)} />
            { data.chart_data.length > 0 ? <Bar { ...config } /> : <NoDataError firstRender={ firstRender } /> }
        </div>
    );
};
