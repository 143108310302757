import React, { useState } from 'react';
import { colorData } from '../../utils/colors.js'
import { Line } from 'react-chartjs-2';
import { dataStructuring } from '../../utils/parseData.js';
import styles from '../label/Heading.module.css'
import { NoDataError } from './NoDataError.js';

export const AvailabilityChart = ({ data, firstRender }) => {

    const [isOpen, setIsOpen] = useState(false)

    const structured_data = dataStructuring(data.chart_data, data.dimensions.x.field, data.dimensions.y.field, data.dimensions.series_field)

    const sources = [...new Set(data.chart_data.map(item => item.source))].sort((a, b) => a.localeCompare(b))
    const formattedData = {
        labels: structured_data.x_fields,
        datasets: sources.map((series_key) => {

            let color = "#CEFA70";
            if (series_key !== null) {
                color = colorData.find((ele) => series_key.toLowerCase().includes(ele.Rival.toLowerCase())) ? colorData.find((ele) => series_key.toLowerCase().includes(ele.Rival.toLowerCase()))["Color"] : "#CEFA70"
            }

            return {
                label: `${series_key}`,
                data: structured_data.data_mapping[series_key].map((ele, index) => ele === null || ele === undefined ? null : sources.indexOf(series_key) + 1),
                borderColor: color,
                backgroundColor: color,
            }
        })
    }


    const options = {
        layout: { padding: { bottom: 20, top: 20 } },
        responsive: true,
        aspectRatio: 4.5,
        interaction: { mode: 'index', intersect: false },
        scales: {
            x: {
                display: false,
                offset: 10,
                title: {
                    display: false,
                    text: "Time"
                },
                grid: { display: false },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: "Rivals"
                },
                grid: { display: false },
                suggestedMin: 0,
                suggestedMax: sources.length + 1,
                reverse: true,
                ticks: {
                    stepSize: 1,
                    callback: (value) => { return (value === sources.length + 1 || value === 0) ? "" : sources[value - 1] }
                },
            },
        },
        elements: { point: { radius: 1 }, line: { borderWidth: 3 } },
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return context.dataset.label
                    }
                }
            }
        },
    };



    return <>
        <div className={ styles.heading }>
            <div className={ styles.text }> { "Scraper Availability" }</div>
            <span className={ styles.pop_up }>
                <span
                    style={ { display: "block", marginTop: isOpen ? "0px" : "3px", fontSize: "16px" } }
                    onClick={ () => setIsOpen(!isOpen) }
                >{ isOpen ? "▲" : "▼" }</span>
            </span>
        </div>
        <div className={ `${styles.expandable_tab} ${isOpen ? styles.show : ""}` } >
            { data.chart_data.length > 0 ? <Line options={ options } data={ formattedData } /> : <NoDataError firstRender={firstRender} /> }
        </div>
    </>;
};

