import React, { useState, useEffect, useRef, useContext } from 'react'
import styles from "./Select.module.css"
import { DataContext } from '../../context/ContextProvider';
import { numberNotation } from '../../utils/numberNotation';
import { useLocation } from 'react-router-dom';

export const MultiSelect = ({ data, handleFunc }) => {
    const { filters, downloadTabFilters, userStatsFilters, filtersPt } = useContext(DataContext)

    const [isOpen, setIsOpen] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [forceUpdate, setForceUpdate] = useState(false) // Only for saved selected option to main variable if user selected
    const dropdownRef = useRef();
    let current_tab = useLocation().pathname

    const [selectedOptions, setSelectedOptions] = useState(() => {
        if (data.default.negation) {
            return { INCLUDE: data.options.filter((initial_option) => !data.default.options.some((default_option) => default_option.value === initial_option.value)), EXCLUDE: data.default.options || [] }
        }
        return { INCLUDE: data.default.options || [], EXCLUDE: data.options.filter((initial_option) => !data.default.options.some((default_option) => default_option.value === initial_option.value)) }
    });

    const [optionNotInData, setOptionNotInData] = useState([])
    const [searchInput, setSearchInput] = useState('');

    const handleToggle = () => {
        setIsOpen(!isOpen);
        if ((selectedOptions.EXCLUDE.length === data.options.length) && (selectedOptions.INCLUDE.length === 0)) { setSelectedOptions((prev) => ({ ...prev, INCLUDE: data.options, EXCLUDE: [] })) }
        setSearchInput("")
    };

    const handleSelectOption = ({ mode, option }) => {
        
        setIsFirstLoad(false)

        if (mode === 'All') {
            selectedOptions.INCLUDE.length === data.options.length ? setSelectedOptions((prev) => ({ ...prev, INCLUDE: [], EXCLUDE: data.options })) : setSelectedOptions((prev) => ({ ...prev, INCLUDE: data.options, EXCLUDE: [] }));
        } else if (mode === 'Only') {
            setSelectedOptions((prev) => ({ ...prev, INCLUDE: [option], EXCLUDE: data.options.filter((initial_opt) => initial_opt.value !== option.value) }))
        } else {
            const includeIndex = selectedOptions.INCLUDE.findIndex((includeOption) => includeOption.value === option.value);
            const excludeIndex = selectedOptions.EXCLUDE.findIndex((excludeOption) => excludeOption.value === option.value);
            const dataIndex = data.options.findIndex((initialOption) => initialOption.value === option.value);

            if (includeIndex !== -1 && excludeIndex === -1 && dataIndex !== -1) setSelectedOptions((prev) => {
                let exclude_options_of_current_data = prev.EXCLUDE.filter((ele) => data.options.some((option) => ele.value === option.value))
                return { ...prev, INCLUDE: prev.INCLUDE.filter((includeOption) => includeOption.value !== option.value), EXCLUDE: [...exclude_options_of_current_data, option] }
            })

            if (includeIndex === -1 && excludeIndex !== -1 && dataIndex !== -1) setSelectedOptions((prev) => ({ ...prev, INCLUDE: [...prev.INCLUDE, option], EXCLUDE: prev.EXCLUDE.filter((excludeOption) => excludeOption.value !== option.value) }))
            if (includeIndex !== -1 && excludeIndex === -1 && dataIndex === -1) setSelectedOptions((prev) => ({ ...prev, INCLUDE: prev.INCLUDE.filter((includeOption) => includeOption.value !== option.value) }))
            if (includeIndex === -1 && excludeIndex === -1 && dataIndex === -1) setSelectedOptions((prev) => ({ ...prev, EXCLUDE: prev.EXCLUDE.filter((excludeOption) => excludeOption.value !== option.value) }))

        }
        setForceUpdate(()=> !forceUpdate)
    };


    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };


    const customSort = (a, b) => {
        const indexA = selectedOptions.INCLUDE.findIndex((ele => ele.value === a.value));
        const indexB = selectedOptions.INCLUDE.findIndex((ele => ele.value === b.value));

        // If both elements are in the 'selected' array, sort based on their order
        if (indexA !== -1 && indexB !== -1) return indexA - indexB
    
        // If only one element is in the 'selected' array, prioritize it
        if (indexA !== -1) return -1
    
        if (indexB !== -1) return 1
    
        // If neither element is in the 'selected' array, sort alphabetically
        return a.value.localeCompare(b.value);
    };


    const filteredOptions = data.options.filter((option) =>
        option.label.toLowerCase().includes(searchInput.toLowerCase())
    );


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSelectedOptions(() => {
            if (data.default.negation) {
                return { INCLUDE: data.options.filter((initial_option) => !data.default.options.some((default_option) => default_option.value === initial_option.value)), EXCLUDE: data.default.options || [] }
            }
            return { INCLUDE: data.default.options || [], EXCLUDE: data.options.filter((initial_option) => !data.default.options.some((default_option) => default_option.value === initial_option.value)) }
        });

        setOptionNotInData(() => {
            let result = data.default.options.filter((option) => !data.options.some((ele) => ele.value === option.value))
            if (data.default.negation === false) {
                return result
            } else {
                return []
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, downloadTabFilters, userStatsFilters, filtersPt])


    useEffect(() => {
        if (isFirstLoad === false) {

            let payload = { options: selectedOptions.INCLUDE, negation: false };
            if (selectedOptions.EXCLUDE < selectedOptions.INCLUDE) { payload = { options: selectedOptions.EXCLUDE.length === 0 && (data.key.value === "city" || data.key.value === "country_code_currency") ? selectedOptions.INCLUDE : selectedOptions.EXCLUDE, negation: selectedOptions.EXCLUDE.length === 0 ? false : true } }
            if ((data.key.value === "city" || data.key.value === "country_code_currency") && selectedOptions.INCLUDE.length === 0) { payload = { options: selectedOptions.EXCLUDE, negation: false } }

            handleFunc({ baseURI: current_tab, filter_key: data.key.value, filter_cur_payload: payload })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceUpdate]);


    return (
        <div key={ data.key.value } className={ styles.filter_box } ref={ dropdownRef }>
            <button className={ styles.trigger_btn } onClick={ handleToggle }>
                <span className={ styles.label } style={ { overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis" } }>
                    <span style={ { fontWeight: "600" } }>{ ((selectedOptions.INCLUDE.length === data.options.length && selectedOptions.EXCLUDE.length === 0) || (selectedOptions.EXCLUDE.length === data.options.length && selectedOptions.INCLUDE.length === 0)) ? data.key.label : `${data.key.label} : ` }</span>
                    <span >{
                        ((selectedOptions.INCLUDE.length === data.options.length && selectedOptions.EXCLUDE.length === 0) || (selectedOptions.EXCLUDE.length === data.options.length && selectedOptions.INCLUDE.length === 0)) ? '' :
                            ((selectedOptions.INCLUDE.length <= selectedOptions.EXCLUDE.length) && selectedOptions.INCLUDE.length !== 0) ? selectedOptions.INCLUDE.map(option => option.label).join("***").trim().split("***").join(', ') :
                                ((selectedOptions.INCLUDE.length > selectedOptions.EXCLUDE.length) && selectedOptions.EXCLUDE.length !== 0) ? `Exclude ${selectedOptions.EXCLUDE.map(option => option.label).join("***").trim().split("***").join(', ')}` :
                                    (selectedOptions.EXCLUDE.length > 0) ? `Exclude ${selectedOptions.EXCLUDE.map(option => option.label).join("***").trim().split("***").join(', ')}` : `${selectedOptions.INCLUDE.map(option => option.label).join("***").trim().split("***").join(', ')}`
                    }</span>
                </span>
                <span className={ styles.hover_all_value }>{
                    ((selectedOptions.INCLUDE.length === data.options.length && selectedOptions.EXCLUDE.length === 0) || (selectedOptions.EXCLUDE.length === data.options.length && selectedOptions.INCLUDE.length === 0)) ? '' :
                        ((selectedOptions.INCLUDE.length <= selectedOptions.EXCLUDE.length) && selectedOptions.INCLUDE.length !== 0) ? selectedOptions.INCLUDE.map(option => option.label).join("***").trim().split("***").join(', ') :
                            ((selectedOptions.INCLUDE.length > selectedOptions.EXCLUDE.length) && selectedOptions.EXCLUDE.length !== 0) ? `Exclude ${selectedOptions.EXCLUDE.map(option => option.label).join("***").trim().split("***").join(', ')}` :
                                (selectedOptions.EXCLUDE.length > 0) ? `Exclude ${selectedOptions.EXCLUDE.map(option => option.label).join("***").trim().split("***").join(', ')}` : `${selectedOptions.INCLUDE.map(option => option.label).join("***").trim().split("***").join(', ')}`
                }</span>
                <span>
                    <span style={ { marginRight: "5px" } }>{
                        ((selectedOptions.INCLUDE.length === data.options.length && selectedOptions.EXCLUDE.length === 0) || (selectedOptions.EXCLUDE.length === data.options.length && selectedOptions.INCLUDE.length === 0)) ? "" :
                            ((selectedOptions.INCLUDE.length <= selectedOptions.EXCLUDE.length) && selectedOptions.INCLUDE.length !== 0) ? `(${selectedOptions.INCLUDE.length})` :
                                ((selectedOptions.INCLUDE.length > selectedOptions.EXCLUDE.length) && selectedOptions.EXCLUDE.length !== 0) ? `(${selectedOptions.EXCLUDE.length})` :
                                    (selectedOptions.EXCLUDE.length > 0) ? `${selectedOptions.EXCLUDE.length}` : `${selectedOptions.INCLUDE.length}` }</span>
                    <span style={ { fontSize: "8px", fontWeight: "100" } }>▼</span>
                </span>

            </button>


            { isOpen && (
                <div className={ styles.dropdown_content }>
                    <div className={ styles.options_head }>
                        <input type='checkbox' checked={ selectedOptions.INCLUDE.length === data.options.length } tabIndex='-1' onChange={ () => handleSelectOption({ mode: "All", option: null }) } />
                        <p>{ data.key.label }</p>
                    </div>
                    <div className={ styles.search_bar }>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000">
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>

                        <input
                            type="text"
                            value={ searchInput }
                            onChange={ (e) => setSearchInput(e.target.value) }
                            placeholder="Type to Search"
                        />
                    </div>
                    <ul className={ styles.options }>
                        { optionNotInData.concat(filteredOptions).sort(customSort).map((option, index) => (
                            <li key={ option.value }>
                                <label className={ styles.select_item } role='option' aria-selected='false' tabIndex='1'>
                                    <div className={ styles.item_renderer }>
                                        <label className={ styles.container }>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    checked={ selectedOptions.INCLUDE.some((includeOption) => includeOption.value === option.value) }
                                                    tabIndex='-1'
                                                    onChange={ () => handleSelectOption({ mode: "Combine", option: option }) }
                                                />
                                                <span style={ { overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis" } }>{ option.label }</span>
                                            </div>
                                            <span className={ styles.record_count }>{ option.record_count ? numberNotation(option.record_count) : "" }</span>
                                            <button className={ styles.only_btn } onClick={ () => handleSelectOption({ mode: "Only", option: option }) }>ONLY</button>
                                        </label>
                                    </div>
                                </label>
                            </li>
                        )) }
                    </ul>
                </div>
            ) }
        </div>
    );
};


