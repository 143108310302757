
const formatDate = (date) => { return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` };

export const defaultDate = () => {
    const currentDate = new Date();

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const last7thDay = new Date(currentDate);
    last7thDay.setDate(currentDate.getDate() - 7);

    return { startDate: formatDate(last7thDay), endDate: formatDate(yesterday) };
}
