const monthToNumber = (month) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months.indexOf(month);
}

const parseData = (element) => {

    // Parse 'YYYY-MM-DD HH' format
    if (/^\d{4}-\d{2}-\d{2} \d{2}$/.test(element)) {
        const [date, hour] = element.split(" ")
        return new Date(`${date}T${hour}:00:00`);
    }

    // Parse 'MMM YY' format
    if (/^[A-Za-z]{3} \d{2}$/.test(element)) {
        const [month, year] = element.split(' ');
        return new Date(`20${year}`, monthToNumber(month));
    }

    // Parse 'MMM YY - MMM YY' format
    if (/^[A-Za-z]{3} \d{2} - [A-Za-z]{3} \d{2}$/.test(element)) {
        const [, end] = element.split(' - ');
        const endDate = parseData(end.trim());
        return endDate; // Return the end date for sorting
    }

    // Parse 'YYYY-MM-DD' format
    if (/^\d{4}-\d{2}-\d{2}$/.test(element)) {
        return new Date(element + 'T00:00:00');
    }

    // Parse 'YYYY' format
    if (/^\d{4}$/.test(element)) {
        return new Date(element + '-01-01T00:00:00');
    }

    return element;
}


export const customSort = (data, key, type) => {
    return data.sort((a, b) => {
        let a_data = parseData(a[key])
        let b_data = parseData(b[key])

        if (typeof a_data === "string" && typeof b_data === "string") {

            return type === "ASC" ? a_data.localeCompare(b_data) : b_data.localeCompare(a_data);
        }

        return type === "ASC" ? a_data - b_data : b_data - a_data;
    });
}