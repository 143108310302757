import React from 'react'
import styles from "./Select.module.css"

export const Dummy = ({ value }) => {
  return (
    <div key={ value } className={ styles.filter_box }>
      <button className={ styles.trigger_btn }></button>
    </div>
  )
}
