import { apiService } from './apiService';

export const FetchRenderData = async (dimensionsFilters, dateRange, fields, getAccessTokenSilently, isAverage, page, limit, cancelTokenSource, firstReload, isPilot) => {

    isPilot = isPilot ? isPilot : false

    const accessToken = await getAccessTokenSilently()
    let endpoint = "/data"

    if (page > 0 && limit > 0) {
        endpoint += `?page=${page}&limit=${limit}`
    }

    const response = await apiService.post(endpoint,
        {
            dateRange: dateRange,
            dimensionsFilters: dimensionsFilters,
            isAverage: isAverage,
            firstReload: firstReload,
            fields: fields,
            isPilot: isPilot
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            cancelToken: cancelTokenSource.token
        }
    );
    return await response.data

}