import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import DataContextProvider from './context/ContextProvider';
import { Authentication } from './auth/Authentication';
import { Authorization } from './auth/Authorization';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Auth0Provider
        domain="product-motor.uk.auth0.com"
        clientId="ncLYgK76ElsBag7lxPaoBvVbrE24UAE3"
        authorizationParams={ {
            audience: "https://project-motor.datastudio.com",
            redirect_uri: window.location.origin
        } }
    >
        <Authentication>
            <Authorization>
                <DataContextProvider>
                    <App />
                </DataContextProvider>
            </Authorization>
        </Authentication>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
