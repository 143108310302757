import React from 'react';
import { Table } from 'antd';
import { Heading } from '../label/Heading';
import { NoDataError } from './NoDataError';


export const DataTable = ({ data, className, firstRender }) => {

    return (
        <div className="table_container">
            <Heading label={ data.label } fields={ data.fields } />
            { data.rows.length > 0 ? <Table
                className={className ? className : ""}
                columns={ data.columns }
                dataSource={ data.rows }
                virtual={ true }
                pagination={ false }
                scroll={ {
                    y: data.height,
                } }
            /> : <NoDataError firstRender={firstRender} /> }
        </div>
    )
}
