import React, { useState, useEffect, useRef, useContext } from 'react'
import styles from "./Select.module.css"
import { numberNotation } from '../../utils/numberNotation';
import { DataContext } from '../../context/ContextProvider';
import { useLocation } from 'react-router-dom';


export const SingleSelect = ({ data, handleFunc, reRender }) => {

    const { filters, downloadTabFilters } = useContext(DataContext)

    const [isOpen, setIsOpen] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dropdownRef = useRef();
    let current_tab = useLocation().pathname

    const [selectedOptions, setSelectedOptions] = useState(() => {
        if (data.default.negation) {
            return { INCLUDE: data.default.options || [], EXCLUDE: data.options.filter((initial_option) => data.default.options.some((default_option) => default_option.value !== initial_option.value)) }
        }
        return { INCLUDE: data.options.filter((initial_option) => data.default.options.some((default_option) => default_option.value === initial_option.value)), EXCLUDE: data.default.options || [] }
    });

    const [searchInput, setSearchInput] = useState('');

    const handleToggle = () => {
        setIsOpen(!isOpen);
        setSearchInput("")
    };


    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const filteredOptions = data.options.filter((option) =>
        option.label.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleSelectOption = ({ mode, option }) => {


        // if (data.key.value === "country_code_currency") return

        setIsFirstLoad(false)

        if (selectedOptions.INCLUDE.length > 0 && option.value === selectedOptions.INCLUDE[0].value) return

        if (mode === 'All') {
            selectedOptions.INCLUDE.length === data.options.length ? setSelectedOptions((prev) => ({ ...prev, INCLUDE: [], EXCLUDE: data.options })) : setSelectedOptions((prev) => ({ ...prev, INCLUDE: data.options, EXCLUDE: [] }));
        } else if (mode === 'Only') {
            setSelectedOptions((prev) => ({ ...prev, INCLUDE: [option], EXCLUDE: data.options.filter((initial_opt) => initial_opt.value !== option.value) }))
        } else {
            const includeIndex = selectedOptions.INCLUDE.findIndex((includeOption) => includeOption.value === option.value);
            const excludeIndex = selectedOptions.EXCLUDE.findIndex((excludeOption) => excludeOption.value === option.value);

            if (includeIndex !== -1 && excludeIndex === -1) setSelectedOptions((prev) => ({ ...prev, INCLUDE: prev.INCLUDE.filter((includeOption) => includeOption.value !== option.value), EXCLUDE: [...prev.EXCLUDE, option] }))
            if (includeIndex === -1 && excludeIndex !== -1) setSelectedOptions((prev) => ({ ...prev, INCLUDE: [...prev.INCLUDE, option], EXCLUDE: prev.EXCLUDE.filter((excludeOption) => excludeOption.value !== option.value) }))
        }

    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSelectedOptions(() => {

            if (data.default.negation) {
                return { INCLUDE: data.default.options || [], EXCLUDE: data.options.filter((initial_option) => data.default.options.some((default_option) => default_option.value !== initial_option.value)) }
            }

            return { INCLUDE: data.options.filter((initial_option) => data.default.options.some((default_option) => default_option.value === initial_option.value)), EXCLUDE: data.default.options || [] }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, downloadTabFilters, reRender])

    useEffect(() => {
        if (isFirstLoad === false) {

            let payload = { options: selectedOptions.INCLUDE, negation: false };
            if (selectedOptions.EXCLUDE < selectedOptions.INCLUDE) { payload = { options: selectedOptions.EXCLUDE, negation: true } }

            handleFunc({ baseURI: current_tab, filter_key: data.key.value, filter_cur_payload: payload })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    return (
        <div key={ data.key.value } className={ styles.select_box } ref={ dropdownRef }>
            <button className={ styles.trigger_btn } onClick={ handleToggle }>
                <span style={ { overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis" } }>
                    <span style={ { fontWeight: "600" } }>{ selectedOptions.INCLUDE.length > 0 ? `${data.key.label} : ` : data.key.label }</span>
                    <span >{ selectedOptions.INCLUDE.length > 0 ? selectedOptions.INCLUDE[0].label.trim() : "" }</span>
                </span>
                <span>
                    <span style={ { fontSize: "8px", fontWeight: "100" } }>▼</span>
                </span>

            </button>

            { isOpen && (
                <div className={ styles.dropdown_content }>
                    <div className={ styles.options_head }>
                        <p>{ data.key.label }</p>
                    </div>
                    <div className={ styles.search_bar }>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000">
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>

                        <input
                            type="text"
                            value={ searchInput }
                            onChange={ (e) => setSearchInput(e.target.value) }
                            placeholder="Type to Search"
                        />
                    </div>
                    <ul className={ styles.options }>
                        { filteredOptions.map((option, index) => (
                            <li key={ index + 1 }>
                                <label className={ styles.select_item } role='option' aria-selected='false' tabIndex='1' onClick={ () => handleSelectOption({ mode: "Only", option: option }) } >
                                    <div className={ styles.item_renderer } >
                                        <label className={ styles.container }>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    checked={ selectedOptions.INCLUDE.some((selectedOption) => selectedOption.value === option.value) }
                                                    readOnly={ true }
                                                    tabIndex='-1'
                                                />
                                                <span style={ { overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis" } }>{ option.label }</span>
                                            </div>
                                            <span className={ styles.record_count } style={ { display: "inline-block" } }>{ option.record_count ? numberNotation(option.record_count) : "" }</span>
                                        </label>
                                    </div>
                                </label>
                            </li>
                        )) }
                    </ul>
                </div>
            ) }
        </div>
    );
};


